import * as types from './actionTypes';  
import investorsApi from '../api/investorsApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doInvestors() {

  return {
    type: types.DO_INVESTORS
  };
}

export function doInvestorsRes(data) {  
  return {
    type: types.DO_INVESTORS_RES, 
    data
  };
}

export function getInvestors() {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      investorsApi.doInvestors().then(data => {
        dispatch(doInvestorsRes(data));
        dispatch(doInvestorsRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}

