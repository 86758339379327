import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import { doCreateAccountsRes } from '../../actions/accountActions';
import Button from '@material-ui/core/Button';
import validate from './formValidation';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText'

const fields = ['first_name', 'last_name', 'email', 'role_type'];

export const TextFieldRedux = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
)
export const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}
export const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl  style={{width: '100%'}}>
    <InputLabel htmlFor="role_type-native-simple">Role Type</InputLabel>
    <Select
      native
      {...input}
      {...custom}
      inputProps={{
        name: 'role_type',
        id: 'role_type-native-simple'
      }}
    >
      {children}
    </Select>
    {renderFromHelper({ touched, error })}
  </FormControl>
)
class CreateAdminForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }
  static getDerivedStateFromProps(props, state) {
		if(props){
      if(props.createAccountsRes.data && props.createAccountsRes.data.accountCreate){
        if(props.createAccountsRes.data.accountCreate.error === 0){
          props.reset();
        }
      }
    }
		return null;
	}
  render() {
    const { handleSubmit, submitting, handleFormSubmit } = this.props;
  
    return (
      <div className="col-md-6">
      
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div style={{marginTop: 30,marginBottom: 30}}>
            <div className="row">
              <div className="col-xs-12 col-md-6" style={{marginTop: 20}}>
                
                <Field
                    name="first_name"
                    component={TextFieldRedux}
                    placeholder="Your first name"
                    label="Your First Name"
                    style={{width: '100%'}}
                />
              </div>
              <div className="col-xs-12 col-md-6" style={{marginTop: 20}}>
                
                <Field
                    name="last_name"
                    component={TextFieldRedux}
                    placeholder="Your last name"
                    label="Your Last Name"
                    style={{width: '100%'}}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-12" style={{marginTop: 20}}>
                <Field
                    name="email"
                    component={TextFieldRedux}
                    placeholder="Your email"
                    label="Your Email"
                    style={{width: '100%'}}
                />
              </div>
              <div className="col-xs-12 col-md-12" style={{marginTop: 20}}>
                <Field
                  name="role_type"
                  component={renderSelectField}
                  label="Role Type"
                >
                  <option value=""></option>
                  <option value={'broker_dealer'}>Brokerdealer Admin</option>
                  <option value={'broker_dealer_manager'}>Brokerdealer Manager</option>
                </Field>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <div style={{textAlign: 'center', marginTop: 30}}>
                  <Button type="submit" variant="contained" color="primary" disabled={submitting} >Send Email</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CreateAdminForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  handleFormSubmit: PropTypes.func,
  createAccountsRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	createAccountsRes: doCreateAccountsRes
});

function mapDispatchToProps(dispatch) {
	return {
		
	};
}
const ClickIPOCreateAdminForm = reduxForm({
  form: 'ClickIPOCreateAdminForm',
  fields,
  validate,
})(CreateAdminForm);


const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ClickIPOCreateAdminForm);
