import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import './changePassword.css';
import PropTypes from 'prop-types';
import ChangePasswordForm from './changepasswordForm/changePasswordForm';
import { submitRemainingDaysChangePassword, remainingDaysChangePasswordRes } from '../../actions/remainingDaysChangePasswordActions';

import { withStyles } from '@material-ui/core/styles';
import LayoutWrapperAuth from '../../component/LayoutWrapperAuth';
import styles from '../login/login-jss';

class ChangePasswordComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            isLoader: false
        }
    }

    handleSubmit(data) {
        let sendRequest = {
            'old_encrypted_password': data.old_encrypted_password,
            'encrypted_password': data.encrypted_password,
        }
        this.props.handleFormSubmit(sendRequest);
    };
    render() {
        const { classes } = this.props;
        return (
            <LayoutWrapperAuth>
                <div className={classes.root}>
                    <Helmet>
                        <title>Change password</title>
                    </Helmet>
                    <div className={classes.container}>
                        <div className={classes.userFormWrap}>
                            <ChangePasswordForm handleFormSubmit={(data) => { this.handleSubmit(data) }} />
                        </div>
                    </div>
                </div>
            </LayoutWrapperAuth>
        )
    }
}

ChangePasswordComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    remainingDaysChangePasswordRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    remainingDaysChangePasswordRes: remainingDaysChangePasswordRes,
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(submitRemainingDaysChangePassword(data))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const ChangeComp = compose(withConnect)(ChangePasswordComponent);

export default withStyles(styles)(ChangeComp);