import * as types from './actionTypes';  
import customerListApi from '../api/customerListApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doCustomerList(request) {

  return {
    type: types.DO_CUSTOMER_LIST, 
    request
  };
}

export function doCustomerListRes(data) {  
  return {
    type: types.DO_CUSTOMER_LIST_RES, 
    data
  };
}

export function getCustomerList(request) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      customerListApi.doCustomerList(request).then(data => {
        dispatch(doCustomerListRes(data));
        dispatch(doCustomerListRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}

