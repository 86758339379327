import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import logo from '../../../assets/images/clickipo_logo.png';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import ArrowForward from '@material-ui/icons/ArrowForward';
import TextField from '@material-ui/core/TextField';
import styles from '../../login/login-jss';

// validation functions
const required = value => (value == null ? 'Required' : undefined);
const email = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i.test(value)
    ? 'Invalid email'
    : undefined
);

export const TextFieldRedux = ({ meta: { touched, error }, input, ...rest }) => {
    return (
      <TextField
        {...rest}
        {...input}
        value={input.value}
        error={touched && Boolean(error)}
      />
    );
};

class ForgotpasswordForm extends React.PureComponent {

    constructor() {
        super();
        this.state = {
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const { handleSubmit, handleFormSubmit, pristine, submitting, classes } = this.props
        return (
            <Fragment>
                <Hidden mdUp>
                    <NavLink to="/" className={classNames(classes.brand, classes.outer)}>
                        <img src={logo} alt={'logo'} />
                    </NavLink>
                </Hidden>
                <Paper className={classNames(classes.paperWrap, classes.petal)}>
                    <Hidden smDown>
                        <div className={classes.topBar}>
                            <NavLink to="/" className={classes.brand}>
                                <img src={logo} alt={'logo'} />
                            </NavLink>
                            <Button size="small" className={classes.buttonLink} component={Link} to="/login">
                                {/* <Icon className={classes.icon}>arrow_forward</Icon> */}
                                Login ?
                            </Button>
                        </div>
                    </Hidden>
                    <Typography variant="h4" className={classes.title} gutterBottom>
                        Forgot Password
                    </Typography>
                    <Typography variant="caption" className={classes.subtitle} gutterBottom align="center">
                        Submit your email and we'll send you a link to reset it.
                    </Typography>
                    <section className={classes.formWrap}>
                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <div>
                                <FormControl className={classes.formControl}>
                                    <Field
                                    name="email"
                                    component={TextFieldRedux}
                                    placeholder="Your Email"
                                    label="Your Email"
                                    required
                                    validate={[required, email]}
                                    className={classes.field}
                                    />
                                </FormControl>
                            </div>
                            <div className={classes.btnArea}>
                                <Button variant="contained" color="primary" type="submit">
                                    Send Reset Link
                                    <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
                                </Button>
                            </div>
                        </form>
                    </section>
                </Paper>
            </Fragment>
        )
    }

}

ForgotpasswordForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleFormSubmit: PropTypes.func,
};
const mapStateToProps = createStructuredSelector({
});
  
function mapDispatchToProps(dispatch) {
    return {
    };
}
const ReduxForgotForm =  reduxForm({
    form: 'ReduxForgotpasswordForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: false,
})(ForgotpasswordForm)

const ForgotForm = connect(mapStateToProps, mapDispatchToProps)(ReduxForgotForm);
export default withStyles(styles)(ForgotForm);