import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './connectedCustomers.scss';
import Dashboard from '../../component/Dashboard';
import { getConnectedCustomers, doConnectedCustomersRes } from '../../actions/connectedCustomersActions';
import { sendCustomerResetPassword, doCustomerResetPassRes } from '../../actions/customerResetPassActions';
import { convertDateFormat } from '../../helper/helper';
import GridTable from '@nadavshaar/react-grid-table';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';

let isConnectedCustomer = false;
let filterData = [];
class ConnectedCustomersComponent extends React.PureComponent {

	constructor(props){
		super(props);
		let columns = [
			{id: 1, field: 'email', label: 'Email Address', visible: true, sortable: false, width: '20%', cellRenderer: this.titleTemp, headerCellRenderer: this.customerCustomHeader},
			{id: 2, field: 'first_name', label: 'Customer First Name', visible: true, sortable: false, width: '20%', cellRenderer: this.titleTemp, headerCellRenderer: this.customerCustomHeader},
			{id: 3, field: 'last_name', label: 'Customer Last Name', visible: true, sortable: false, width: '20%', cellRenderer: this.titleTemp, headerCellRenderer: this.customerCustomHeader},
			{id: 4, field: 'created_at', label: 'Created', visible: true, sortable: false, width: '20%', cellRenderer: this.titleTemp, headerCellRenderer: this.customerCustomHeader},
			{id: 5, field: 'last_sign_in_at', label: 'Last Login', visible: true, sortable: false, width: '20%', cellRenderer: this.titleTemp, headerCellRenderer: this.customerCustomHeader},

		];
		isConnectedCustomer = false;
		this.state = {
			cols: columns,
			uiRender: false,
			filterBy: 'first_name', 
			searching: '',
			offeringDetail: {},
			connectedCustomersList: [],
			pagenum: 1,
			tableFilter: {
                field: null,
                order: null,
			},
			isFetchCustomer: false,
			lastConnectedCustomersList: 0,
			connectedCustomerslength: 0,
			loadMoreAll: 'more',
		}
	}
	componentDidMount() {
		let payload = {
			pagenum: this.state.pagenum
		}
		this.props.fetchAllCustomerList(payload);
		isConnectedCustomer = true;
	}

	static getDerivedStateFromProps(props, state) {
		if (props.connectedCustomersRes) {
			if (props.connectedCustomersRes.data && props.connectedCustomersRes.data.connectedCustomers) {
				if (props.connectedCustomersRes.data.connectedCustomers.message === 'Success' && isConnectedCustomer) {
					let isConnectedCustomerslength = state.connectedCustomerslength;
					if (state.connectedCustomerslength === 0) {
						isConnectedCustomerslength = props.connectedCustomersRes.data.connectedCustomers.data.length
					}
					isConnectedCustomer = false;
					if (state.isFetchCustomer) {
						return {
							lastConnectedCustomersList: props.connectedCustomersRes.data.connectedCustomers.data.length,
							connectedCustomerslength: isConnectedCustomerslength,
							connectedCustomersList: state.connectedCustomersList.concat(props.connectedCustomersRes.data.connectedCustomers.data),
							isFetchCustomer: false
						};
					} else {
						return {
							lastConnectedCustomersList: props.connectedCustomersRes.data.connectedCustomers.data.length,
							connectedCustomerslength: isConnectedCustomerslength,
							connectedCustomersList: props.connectedCustomersRes.data.connectedCustomers.data
						};
					}
					
				}
			}
		}
		return null;
	}
	customerCustomHeader = ({tableManager, column, mode, ref, checked, disabled, indeterminate, onChange}) => {
        return <div>{column.label} {this.onSortFunction(column.field)}</div>
	}
	titleTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (value) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex'}}>
					<Tooltip title={value !== undefined ? value : ''} interactive >
						<div className="less-text" style={{width: '90%'}}>{value}</div>
					</Tooltip>
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button color="primary" style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
					</Tooltip>
				</div>
			)
		}
	}
	dropDownSearchByAllCus = (event) => {
		this.setState({
			filterBy: event.target.value,
		}, () => {
		});
	}
	searchCustomer = (event) => {
		if (this.state.filterBy === 'email') {
			this.setState({
				searching: event.target.value.toLowerCase().trim(),
			}, () => {
			});
		} else {
			this.setState({
				searching: event.target.value,
			}, () => {
			});
		}
	}

	onSortFunction(field) {
		return (<span>
			{
				((this.state.tableFilter.field !== field)) &&
				<button className="sort-button sort-button-rotate" onClick={() => { this.doSort(field, 'asc'); }}><i className="fa fa-exchange" aria-hidden="true"></i></button>
			}
			{
				((this.state.tableFilter.order === 'asc') && (this.state.tableFilter.field === field)) &&
				<button className="sort-button" onClick={() => { this.doSort(field, 'desc'); }}><i className="fa fa-sort-amount-asc" aria-hidden="true"></i></button>
			}
			{
				((this.state.tableFilter.order === 'desc') && (this.state.tableFilter.field === field)) &&
				<button className="sort-button" onClick={() => { this.doSort(field, 'asc'); }}><i className="fa fa-sort-amount-desc" aria-hidden="true"></i></button>
			}
		</span>);
        
	}
	doSort(field, order) {
		if (this.state.pagenum !== -1) {
			if (this.state.connectedCustomersList) {
				if (order === null || order === 'asc') {
					this.setState({
						isSortActive: 'desc',
						tableFilter: {
							field,
							order,
						},
					}, () => { });
					let payload = {
						pagenum: this.state.pagenum,
						sortby : field,
						sorttype: order,
					}
					if (this.state.searching !== '') {
						payload.search_key = this.state.filterBy;
						payload.search_keyword = this.state.searching;
					}
					this.props.fetchAllCustomerList(payload);
					isConnectedCustomer = true;
				} else {
					this.setState({
						isSortActive: 'asc',
						tableFilter: {
							field,
							order,
						},
					}, () => { });
					let payload = {
						pagenum: this.state.pagenum,
						sortby : field,
						sorttype: order,
					}
					if (this.state.searching !== '') {
						payload.search_key = this.state.filterBy;
						payload.search_keyword = this.state.searching;
					}
					this.props.fetchAllCustomerList(payload);
					isConnectedCustomer = true;
				}
			}
		} else {
			if (order === null || order === 'asc') {
				this.setState({
					tableFilter: {
						field,
						order,
					},
				}, () => { });
				filterData = filterData.sort((a, b) => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0));
				this.setState({
					isUIrender: !this.state.isUIrender
				});
			} else {
				this.setState({
					tableFilter: {
						field,
						order,
					},
				}, () => { });
				filterData = filterData.sort((a, b) => (a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0));
				this.setState({
					isUIrender: !this.state.isUIrender
				});
			}
		}
	}
	handleClickSearch = () => {
		if (this.state.pagenum !== -1) {
			if (this.state.filterBy === 'email') {

				let payload = {
					pagenum: 1,
				}
				if (this.state.tableFilter.field !== null) {
					payload.sortby = this.state.tableFilter.field;
					payload.sorttype = this.state.tableFilter.order;
				}
				
				if (this.state.searching !== '') {
					payload.search_keyword = this.state.searching.toLowerCase().trim();
					payload.search_key = this.state.filterBy;
				}
				this.setState({pagenum: 1})
				this.props.fetchAllCustomerList(payload);
				isConnectedCustomer = true;
			} else {
				let payload = {
					pagenum: 1,
				}
				if (this.state.tableFilter.field !== null) {
					payload.sortby = this.state.tableFilter.field;
					payload.sorttype = this.state.tableFilter.order;
				}
				
				if (this.state.searching !== '') {
					payload.search_keyword = this.state.searching.toLowerCase().trim();
					payload.search_key = this.state.filterBy;
				}
				this.setState({pagenum: 1})
				this.props.fetchAllCustomerList(payload);
				isConnectedCustomer = true;
			}
		}
		
	}
	fetchData = () => {
		if (this.state.lastConnectedCustomersList === this.state.connectedCustomerslength) {
			let payload = {
				pagenum: this.state.pagenum + 1
			}
			if (this.state.tableFilter.field !== null) {
				payload.sortby = this.state.tableFilter.field;
				payload.sorttype = this.state.tableFilter.order;
			}
			
			if (this.state.searching !== '') {
				payload.search_keyword = this.state.searching;
				payload.search_key = this.state.filterBy;
			}
			this.setState({
				pagenum: this.state.pagenum + 1,
				isFetchCustomer: true
			})
			this.props.fetchAllCustomerList(payload);
			isConnectedCustomer = true;
		}
		
	}
	doRefreshCustomer = () => {
		let request = {
			pagenum: 1,
        }
        if (this.state.tableFilter.field !== null) {
            request.sortby = this.state.tableFilter.field;
            request.sorttype = this.state.tableFilter.order;
		}
		this.setState({pagenum: 1})
		isConnectedCustomer = true;
		
        this.props.fetchAllCustomerList(request);
	}
	handleBlur = () => {
		if (this.state.searching === '') {
			if (this.state.pagenum !== -1) {
                this.doRefreshCustomer();
            }
		}
	}
	downLoadCSV = (csvData) => {
		let csvContent = 'data:text/csv;charset=utf-8,';
			csvContent += 'First Name, Last Name, Email, Created Date \r\n';
			csvData.forEach(function (rowArray) {
				const newRow = rowArray.first_name + ',' + rowArray.last_name + ',' + rowArray.email + ',' + rowArray.created_at;
				csvContent += newRow + '\r\n'; // add carriage return
			});
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'customerList.csv');
		document.body.appendChild(link); // Required for FF
		link.click();
	}
	loadMore = () => {
		this.fetchData();
        this.setState({ loadMoreAll: 'more' });
	}
	showAll = () => {
        this.setState({
            connectedCustomersList: [],
            loadMoreAll: 'all'
        }, () => {
			let payload = {
				pagenum: -1
			}
			this.setState({
				pagenum: payload.pagenum,
				isFetchCustomer: true,
				searching: '',
				tableFilter: {
					field: null,
					order: null,
				},
			})
			this.props.fetchAllCustomerList(payload);
			isConnectedCustomer = true;
        })
    }
	render() {
		const { connectedCustomersList, filterBy } = this.state;
		if (connectedCustomersList) {
			for (let item of connectedCustomersList) {

				if (item.created_at === null || item.created_at === "Not Available") {
					item.created_at1 = "Not Available";
				} else {
					item.created_at1 = convertDateFormat(item.created_at);
				}

				item.fullname = item.first_name + " " + item.last_name
			}
		}
		filterData = connectedCustomersList;
		if (this.state.pagenum === -1) {
			if (filterBy === 'first_name') {
				filterData = this.state.searching ? filterData.filter((row) => row.first_name.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 ) : filterData;
			} else if (filterBy === 'last_name') {
				filterData = this.state.searching ? filterData.filter((row) => row.last_name.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 || row.email.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 ) : filterData;
			} else if (filterBy === 'email') {
				filterData = this.state.searching ? filterData.filter((row) => row.email.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 ) : filterData;
			}
		}
		return (
			<Dashboard title="Connected Customers"  pageTitle="Connected Customers" >
				<div className="customer_content">
					<div>
						<div className="row">
							<div className="col-md-5">
								<div className="totalCustomers">Total number of customers : {connectedCustomersList && <span> {connectedCustomersList.length}</span>}</div>
							</div>
							<div className="col-md-7">
								<div className=" customer-filters">
									<div className="filterbyContent">Search By</div>
									<select className="selectField" value={this.state.filterBy} onChange={this.dropDownSearchByAllCus} style={{ background: 'transparent' }} >
										<option value="first_name" >First Name</option>
										<option value="last_name" >Last Name</option>
										<option value="email" >Email</option>
									</select>
									<TextField id="searchInput" type="text" placeholder="Search" defaultValue={this.state.searching} onChange={this.searchCustomer}
									InputProps={{
										endAdornment: (
												<InputAdornment position="start">
													{
                                                        this.state.searching !== '' &&
                                                        <Button variant="contained" color="primary" style={{ borderRadius: '0px 4px 4px 0px', minWidth: 30, top: 19, right: 0, left: 8, height: 38, background: 'transparent', color: '#333' }} onClick={() => this.handleBlur()}  ><CloseIcon className="close-icon-for-search" /></Button>
                                                    }
													<Button variant="contained" color="primary" style={{borderRadius: '0px 4px 4px 0px', minWidth: 50, top: 19, right: 0, left: 8, height: 38}} onClick={() => this.handleClickSearch()} disabled={this.state.searching !== '' ? false : true}><SearchIcon /></Button>
												</InputAdornment>
										)
									}}
									/>
									{connectedCustomersList && connectedCustomersList.length > 0 &&
										<Button variant="contained" color="primary" onClick={() => this.downLoadCSV(connectedCustomersList)}>Download CSV</Button>
									}
								</div>
							</div>
						</div>
						<div className="row newcustomerTableList">
							<div className="col-12 tableheight" style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 0 }}>
								<div className="table-responsive " id="mainTable">
									
									<GridTable
										columns={this.state.cols}
										rows={connectedCustomersList}
										isPaginated={false}
										isVirtualScroll={true}
										showSearch={false}
										showRowsInformation={false}
										isHeaderSticky={true}
									/>
									<div className="col-md-12 mt-2">
										{
											(this.state.lastConnectedCustomersList === this.state.connectedCustomerslength) ?
												<Button variant="contained" color="primary" onClick={() => this.loadMore()} style={{ marginRight: 8, marginBottom: 8 }} disabled={this.state.pagenum === -1}>Load more</Button>
												:
												<Button variant="contained" color="primary" style={{ marginRight: 8, marginBottom: 8 }} disabled={true}>Load more</Button>
										}
										<Button variant="contained" color="primary" onClick={() => this.showAll()} style={{ marginRight: 8, marginBottom: 8 }} >Load all</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Dashboard>
		)
	}
}

ConnectedCustomersComponent.propTypes = {
	connectedCustomersRes: PropTypes.any,
	customerResetPassRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	connectedCustomersRes: doConnectedCustomersRes,
	customerResetPassRes: doCustomerResetPassRes,
});

function mapDispatchToProps(dispatch) {
	return {
		fetchAllCustomerList: (data) => dispatch(getConnectedCustomers(data)),
		sendCustomerResetPassword: (data) => dispatch(sendCustomerResetPassword(data)),
		
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ConnectedCustomersComponent);
