import * as types from './actionTypes';
import editProfileApi from '../api/editProfileApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function doEditprofile() {
  return {
    type: types.DO_EDITPROFILE,
  };
}

export function doEditprofileRes(data) {
  return {
    type: types.DO_EDITPROFILE_RES,
    data
  };
}

export function submit_profile() {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      editProfileApi.doEditprofile().then(data => {
        dispatch(doEditprofileRes(data));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}