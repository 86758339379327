import * as types from './actionTypes';  
import profileApi from '../api/profileApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function doProfile(data) {

  return {
    type: types.DO_GETPROFILE, 
    data
  };
}

export function doProfileRes(data) {  
  return {
    type: types.DO_GETPROFILE_RES, 
    data
  };
}

export function getProfile(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      profileApi.doProfile(data).then(data => {
        dispatch(doProfileRes(data));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}

