
export default function validate(values) {
  const errors = {};
  // const emailReg = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/i);
  
  if (!values.first_name) {
    errors.first_name = 'Please enter first name';
  }
  if (!values.last_name) {
    errors.last_name = 'Please enter last name';
  }
  // if (!values.email || (values.email && !values.email.match(emailReg))) {
  //   errors.email = 'Please enter a valid email';
  // }
  if (!values.email) {
    errors.email = 'Please enter a valid email';
  }
  if (!values.role_type) {
    errors.role_type = 'Please select Role Type';
  }
  return errors;
}
