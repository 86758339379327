import { getItem } from '../utils/localStore';
import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class userBouncesApi {  
    static doUserBounces(ext_id) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'GET',
                headers: ajaxRequestHeaders,
            }
            return fetch(BASE_URL + `/prospectus/bounces?ext_id=${ext_id}`, body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }

    static doUserBounceDetail(url) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'GET',
                headers: ajaxRequestHeaders,
            }
            return fetch(BASE_URL + `/prospectus/bounces/details?url=${url}`, body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }

    static doResendUserBounces(request) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(request)
            }
            return fetch(BASE_URL + `/prospectus/bounces/resend`, body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
    static doPrintUserBounces(request) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(request)
            }
            return fetch(BASE_URL + `/prospectus/bounces/print`, body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}  

export default userBouncesApi;