import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ThemeWrapper from './component/LayoutWrapper';
import Loader from "react-loader";
import { loadingRes } from './actions/loadingActions';

import LoginComponent from './container/login/login';
import ForgetPasswordComponent from './container/forgetpassword/forgetPassword';
import ResetPasswordComponent from './container/passwordreset/reset-password';
import ChangePasswordComponent from './container/changepassword/changePassword';
import OfferingComponent from './container/offerings/offerings';
import OfferingCallBacksComponent from './container/offeringCallBacks/offeringCallBacks';
import ConnectedCustomersComponent from './container/connectedCustomers/connectedCustomers';
import InvestorsComponent from './container/investors/investors';
import OfferingInvestorsComponent from './container/offeringInvestors/offeringInvestors';
import OfferingCommunicationComponent from './container/offeringCommunication/offeringCommunication';
import InvestorCommunicationComponent from './container/investorCommunication/investorCommunication';
import UserBouncesComponent from './container/userBounceList/userBounceList';
import InvestorDetailComponent from './container/investorDetail/investorDetail';
import AccountComponent from './container/account/account';
import ProfileComponent from './container/profile/profile';
import TokenError from './utils/invalidTokenError';
import { getItem } from './utils/localStore';
import IdleTimer from 'react-idle-timer';
import Swal from 'sweetalert2';
import { logout } from './helper/helper';
const TOKEN = getItem('auth_token');
const LEVEL = Number(getItem('level'));
class App extends React.PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			timeout: 1000 * 60 * 30,
			showModal: false,
			userLoggedIn: false,
			isTimedOut: false,
			isLoader: true
		}

		this.idleTimer = null
		this.onAction = this._onAction.bind(this)
		this.onActive = this._onActive.bind(this)
		this.onIdle = this._onIdle.bind(this)
	}
	_onAction(e) {
		this.setState({
			isTimedOut: false
		})
	}

	_onActive(e) {
		this.setState({
			isTimedOut: false
		})
	}

	_onIdle(e) {
		const isTimedOut = this.state.isTimedOut
		if (isTimedOut) {
			logout();
		} else {
			this.setState({
				showModal: true
			})
			this.idleTimer.reset();
			this.setState({
				isTimedOut: true
			})
			Swal.fire({
				// position: 'top-end',
				icon: 'info',
				title: '<strong style="font-size: 24px;padding-top: 20px;padding-bottom: 20px;">You will get timed out</strong>',
				padding: '3em',
				width: 400,
				showConfirmButton: false,
				allowOutsideClick: true,
				timer: 30000,
				heightAuto: false,
				onClose: () => {
					logout();
				}
			}).then((result) => {
				logout();
			})
		}

	}
	static getDerivedStateFromProps(props, state) {
		if (props.loadingRes) {
			if (props.loadingRes.data) {
				if (props.loadingRes.data.isLoading === true || props.loadingRes.data.isLoading === false) {
					if (props.loadingRes.data.isLoading === false) {
						return {
							isLoader: true
						};
					} else {
						return {
							isLoader: false
						};
					}
				}

			}
		}
		return state
	}
	render() {
		let redirectURL = "/?red_url=" + window.location.href;
		var options = {
			lines: 13,
			length: 20,
			width: 10,
			radius: 30,
			corners: 1,
			rotate: 0,
			direction: 1,
			color: "#fff",
			speed: 1,
			trail: 60,
			shadow: false,
			hwaccel: false,
			zIndex: 2e9,
			top: "50%",
			left: "50%",
			scale: 1.0,
			loadedClassName: "loadedContent"
		};
		if (!TOKEN) {
			return (
				<div style={{ height: '100%' }}>
					<Loader
						loaded={this.state.isLoader}
						options={options}
						className="spinner"
					></Loader>
					<ThemeWrapper>
						<React.Fragment>
							<BrowserRouter>
								<Switch>
									<Route exact={true} path="/" component={LoginComponent} />
									<Route path="/forgetpassword" component={ForgetPasswordComponent} />
									<Route path="/reset-password" component={ResetPasswordComponent} />
									<Route path="/changepassword" component={ChangePasswordComponent} />
									<Route path="/invalidToken" component={TokenError} />
									<Route render={() => (<Redirect to={redirectURL} />)} />
								</Switch>
							</BrowserRouter>
						</React.Fragment>
					</ThemeWrapper>
				</div>
			)
		}
		if (TOKEN && (LEVEL === 10 || LEVEL === 11 )) {
			return (
				<div style={{ height: '100%' }}>
					<Loader
						loaded={this.state.isLoader}
						options={options}
						className="spinner"
					></Loader>
					<IdleTimer
						ref={ref => { this.idleTimer = ref }}
						element={document}
						onActive={this.onActive}
						onIdle={this.onIdle}
						onAction={this.onAction}
						debounce={250}
						timeout={this.state.timeout} />
					<ThemeWrapper>
						<React.Fragment>
							<BrowserRouter>
								<Switch>
									<Route path="/offerings" component={OfferingComponent} />
									<Route path="/offering-callbacks" component={OfferingCallBacksComponent} />
									<Route path="/connected-customers" component={ConnectedCustomersComponent} />
									<Route path="/investors" component={InvestorsComponent} />
									<Route path="/accounts" component={AccountComponent} />
									<Route path="/offering-investors/:id" component={OfferingInvestorsComponent} />
									<Route path="/offering-communication" component={OfferingCommunicationComponent} />
									<Route path="/investor-communication" component={InvestorCommunicationComponent} />
									<Route path="/investor-detail" component={InvestorDetailComponent} />
									<Route path="/user-bounces" component={UserBouncesComponent} />
									<Route path="/profile" component={ProfileComponent} />
									<Route path="/invalidToken" component={TokenError} />
									<Route render={() => (<Redirect to="/offerings" />)} />
								</Switch>
							</BrowserRouter>
						</React.Fragment>
					</ThemeWrapper>
				</div>
			)
		}
	}
}

App.propTypes = {
	loadingRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	loadingRes: loadingRes,
});

function mapDispatchToProps(dispatch) {
	return {

	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(App);
