export const DO_LOGIN = 'DO_LOGIN';
export const DO_LOGIN_RES = 'DO_LOGIN_RES';

export const DO_LOADING_RES = 'DO_LOADING_RES';

export const CHECK_USER = 'CHECK_USER';
export const CHECK_USER_RES = 'CHECK_USER_RES';

export const DO_FORGOTPASSWORD = 'DO_FORGOTPASSWORD';
export const DO_FORGOTPASSWORD_RES = 'DO_FORGOTPASSWORD_RES';

export const DO_RESETPASSWORD = 'DO_RESETPASSWORD';
export const DO_RESETPASSWORD_RES = 'DO_RESETPASSWORD_RES';

export const REMAINING_DAYS_CHANGEPASSWORD = 'REMAINING_DAYS_CHANGEPASSWORD';
export const REMAINING_DAYS_CHANGEPASSWORD_RES = 'REMAINING_DAYS_CHANGEPASSWORD_RES';

export const DO_EDITPROFILE = 'DO_EDITPROFILE';
export const DO_EDITPROFILE_RES = 'DO_EDITPROFILE_RES';

export const DO_OFFERING = 'DO_OFFERING';
export const DO_OFFERING_RES = 'DO_OFFERING_RES';

export const DO_OFFERING_DETAILS = 'DO_OFFERING_DETAILS';
export const DO_OFFERING_DETAILS_RES = 'DO_OFFERING_DETAILS_RES';

export const DO_ACCOUNTS = 'DO_ACCOUNTS';
export const DO_ACCOUNTS_RES = 'DO_ACCOUNTS_RES';

export const DO_CREATE_ACCOUNTS = 'DO_CREATE_ACCOUNTS';
export const DO_CREATE_ACCOUNTS_RES = 'DO_CREATE_ACCOUNTS_RES';

export const DO_GETPROFILE = 'DO_GETPROFILE';
export const DO_GETPROFILE_RES = 'DO_GETPROFILE_RES';

export const DO_OFFERING_CALLBACKS = 'DO_OFFERING_CALLBACKS';
export const DO_OFFERING_CALLBACKS_RES = 'DO_OFFERING_CALLBACKS_RES';

export const DO_CONNECTED_CUSTOMERS = 'DO_CONNECTED_CUSTOMERS';
export const DO_CONNECTED_CUSTOMERS_RES = 'DO_CONNECTED_CUSTOMERS_RES';

export const DO_INVESTORS = 'DO_INVESTORS';
export const DO_INVESTORS_RES = 'DO_INVESTORS_RES';

export const DO_CUSTOMER_LIST = 'DO_CUSTOMER_LIST';
export const DO_CUSTOMER_LIST_RES = 'DO_CUSTOMER_LIST_RES';

export const FETCH_BUYINGPOWER = 'FETCH_BUYINGPOWER';
export const FETCH_BUYINGPOWER_RES = 'FETCH_BUYINGPOWER_RES';

export const DO_DELETE_ORDER = 'DO_DELETE_ORDER';
export const DO_DELETE_ORDER_RES = 'DO_DELETE_ORDER_RES';

export const DO_UPDATE_REQUESTED_AMOUNT = 'DO_UPDATE_REQUESTED_AMOUNT';
export const DO_UPDATE_REQUESTED_AMOUNT_RES = 'DO_UPDATE_REQUESTED_AMOUNT_RES';

export const DO_ACTIVE_OFFERING = 'DO_ACTIVE_OFFERING';
export const DO_ACTIVE_OFFERING_RES = 'DO_ACTIVE_OFFERING_RES';

export const DO_USER_DETAILS = 'DO_USER_DETAILS';
export const DO_USER_DETAILS_RES = 'DO_USER_DETAILS_RES';

export const DO_OFFERING_COMMUNICATION = 'DO_OFFERING_COMMUNICATION';
export const DO_OFFERING_COMMUNICATION_RES = 'DO_OFFERING_COMMUNICATION_RES';

export const DO_USER_BOUNCES = 'DO_USER_BOUNCES';
export const DO_USER_BOUNCES_RES = 'DO_USER_BOUNCES_RES';

export const DO_USER_BOUNCES_DETAIL = 'DO_USER_BOUNCES_DETAIL';
export const DO_USER_BOUNCES_DETAIL_RES = 'DO_USER_BOUNCES_DETAIL_RES';

export const DO_USER_RESEND_BOUNCES = 'DO_USER_RESEND_BOUNCES';
export const DO_USER_RESEND_BOUNCES_RES = 'DO_USER_RESEND_BOUNCES_RES';

export const DO_USER_PRINT_BOUNCES = 'DO_USER_PRINT_BOUNCES';
export const DO_USER_PRINT_BOUNCES_RES = 'DO_USER_PRINT_BOUNCES_RES';

export const DO_FETCH_ORDERS_METADATA = 'DO_FETCH_ORDERS_METADATA';
export const DO_FETCH_ORDERS_METADATA_RES = 'DO_FETCH_ORDERS_METADATA_RES';

export const DO_CUSTOMER_RESET_PASSWORD = 'DO_CUSTOMER_RESET_PASSWORD';
export const DO_CUSTOMER_RESET_PASSWORD_RES = 'DO_CUSTOMER_RESET_PASSWORD_RES';

export const DO_OFFERING_METADATA = 'DO_OFFERING_METADATA';
export const DO_OFFERING_METADATA_RES = 'DO_OFFERING_METADATA_RES';
