export const BASE_URL =
  process.env.REACT_APP_BRANCH === "dev"
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BRANCH === "stage"
    ? process.env.REACT_APP_BASE_URL_STAGE
    : process.env.REACT_APP_BASE_URL;

export const OPEN_GLOBAL_LOADER = "clickipo/OPEN_GLOBAL_LOADER";
export const CLOSE_GLOBAL_LOADER = "clickipo/CLOSE_GLOBAL_LOADER";
export const INTERCOM_ID = "qzt14uk3";
export const DEBUG_CONFIG = 1;
