import * as types from './actionTypes';  
import accountApi from '../api/accountApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';
export function doAccounts() {

  return {
    type: types.DO_ACCOUNTS
  };
}

export function doAccountsRes(data) {  
  return {
    type: types.DO_ACCOUNTS_RES, 
    data
  };
}

export function getAccounts() {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      accountApi.doAccounts().then(data => {
        dispatch(doAccountsRes(data));
        dispatch(doAccountsRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}

export function doCreateAccounts(payload) {

  return {
    type: types.DO_CREATE_ACCOUNTS,
    payload
  };
}

export function doCreateAccountsRes(data) {  
  return {
    type: types.DO_CREATE_ACCOUNTS_RES, 
    data
  };
}

export function submitCreateAccounts(payload) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      accountApi.doCreateAccounts(payload).then(data => {
        dispatch(doCreateAccountsRes(data));
        dispatch(doCreateAccountsRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
        
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}

