import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './account.scss';
import Dashboard from '../../component/Dashboard';
import { getAccounts, doAccountsRes } from '../../actions/accountActions';
import { submitCreateAccounts, doCreateAccountsRes } from '../../actions/accountActions';
import { sendCustomerResetPassword, doCustomerResetPassRes } from '../../actions/customerResetPassActions';
import CreateAdminForm from './form';
import Button from '@material-ui/core/Button';
import GridTable from '@nadavshaar/react-grid-table';
import Tooltip from '@material-ui/core/Tooltip';
import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { DialogActions } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

let isAccountList = false;
let isCreateAccount = false;
class AccountComponent extends React.PureComponent {

	constructor(props){
		super(props);
		isAccountList = false;
		isCreateAccount = false;
		let columns = [
			{ id: 1, width: '20%', field: 'first_name', label: 'First Name', visible: true, sortable: true, cellRenderer: this.titleTemp },
			{ id: 2, width: '20%', field: 'last_name', label: 'Last Name', visible: true, sortable: true, cellRenderer: this.titleTemp },
			{ id: 3, width: '20%', field: 'email', label: 'Email', visible: true, sortable: true, cellRenderer: this.titleTemp },
			{ id: 4, width: '20%', field: 'role_name', label: 'Role', visible: true, sortable: true, cellRenderer: this.titleTemp },
		];
		let roleLevel = JSON.parse(localStorage.getItem('level'));
		if (Number(roleLevel) === 10) {
			columns.push({ id: 5, width: '20%', field: 'action', label: 'Action', visible: true, sortable: false, cellRenderer: this.actionTemplate})
		}
		this.state = {
			cols: columns,
			accountList: [],
			isAccount: false,
			customerDetail: null,
			openConfirmation: false
		}
	}
	componentDidMount() {
		this.props.getAccounts();
		isAccountList = true;
	}

	static getDerivedStateFromProps(props, state) {
		if (props.accountsRes) {
			if (props.accountsRes.data && props.accountsRes.data.accountList) {
				if (props.accountsRes.data.accountList.error === 0 && isAccountList) {
					isAccountList = false;
					let arrData = props.accountsRes.data.accountList.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };
					return {
						accountList: arrData,
					};
				}
			}
		}
		if (props.customerResetPassRes) {
			if (props.customerResetPassRes.data && props.customerResetPassRes.data.customerResetPass) {
				if (props.customerResetPassRes.data.customerResetPass.message === 'Success') {
					return {
						openConfirmation: false
					};
				}
			}
		}
		if(props.createAccountsRes){
			if(props.createAccountsRes.data && props.createAccountsRes.data.accountCreate) {
				if(props.createAccountsRes.data.accountCreate.message === 'Success' && isCreateAccount) {
					isCreateAccount = false;
					props.getAccounts();
					isAccountList = true;
					return {
						isAccount: false,
					};
				}
			}
		}
		return null;
	}
	openAccount = () => {
		this.setState({
			isAccount: !this.state.isAccount
		})
	}
	handleFormSubmit = (data) => {
		isCreateAccount = true;
		this.props.submitCreateAccounts(data);
	}
	titleTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (value) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex'}}>
					<Tooltip title={value !== undefined ? value : ''} interactive >
						<div className="less-text" style={{width: '90%'}}>{value}</div>
					</Tooltip>
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button color="primary" style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
					</Tooltip>
				</div>
			)
		}
	}
	actionTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return (<div className="rgt-cell-inner">
			<Tooltip title="Reset password">
				<Button style={{ width: 'auto', margin: 3 }} variant="outlined" size="medium" color="primary" onClick={() => this.openConfirmationModal(data)}><LockIcon /></Button>
			</Tooltip>
		</div>);
	}
	resetPasswordClick = () => {
		this.props.sendCustomerResetPassword(this.state.customerDetail.email);
	}
	openConfirmationModal = (data) => {
		this.setState({
			customerDetail: data,
			openConfirmation: true
		});
	}
	closeConfirmationModal = () => {
		this.setState({
			openConfirmation: false
		});
	}
	render() {
		const { accountList, customerDetail } = this.state;

		return (
			<Dashboard title="Accounts" pageTitle="Accounts" >
				<div className="offering_content">
					<div className="col-md-12 mb-3" style={{textAlign: 'right'}}>
						<Button variant="contained" color="primary" onClick={this.openAccount}>Add New Account</Button>
					</div>
					{
						(this.state.isAccount) &&
						<div className="row justify-content-md-center">
							<CreateAdminForm handleFormSubmit={(data) => this.handleFormSubmit(data)} />
						</div>
					}
					<div className="account-table">
						<GridTable
							columns={this.state.cols}
							rows={accountList}
							isPaginated={true}
							isVirtualScroll={true}
							showSearch={true}
							showRowsInformation={false}
							isHeaderSticky={true}
						/>
						<Dialog
							onClose={this.closeConfirmationModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.openConfirmation}
							maxWidth={"sm"}
							fullWidth={true}
							scroll={'paper'}
						>
							<DialogTitle onClose={this.closeConfirmationModal} className="offering-detail-title">Reset Password
										</DialogTitle>
							<DialogContent>
								<div className="col-md-12">
									<h5>
										Are you sure you want to send password recovery email to
													&nbsp; <span > {customerDetail && customerDetail.email ? customerDetail.email : ''} ?</span>
									</h5>
								</div>
							</DialogContent>
							<DialogActions style={{ display: 'block' }}>
								<div style={{ textAlign: 'center' }}>
									<Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={() => this.resetPasswordClick()}> Send </Button>
									<Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary" onClick={() => this.closeConfirmationModal()}>
										Cancel
												</Button>
								</div>
							</DialogActions>
						</Dialog>
					</div>  
				</div>
			</Dashboard>
		)
	}
}

AccountComponent.propTypes = {
	accountsRes: PropTypes.any,
	createAccountsRes: PropTypes.any,
	customerResetPassRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	accountsRes: doAccountsRes,
	createAccountsRes: doCreateAccountsRes,
	customerResetPassRes: doCustomerResetPassRes,
});

function mapDispatchToProps(dispatch) {
	return {
		getAccounts: () => dispatch(getAccounts()),
		submitCreateAccounts: (data) => dispatch(submitCreateAccounts(data)),
		sendCustomerResetPassword: (data) => dispatch(sendCustomerResetPassword(data)),
		
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AccountComponent);
