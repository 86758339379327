import * as types from './actionTypes';  
import buyingPowerApi from '../api/buyingPowerApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function fetchBuyingPower(email) {

  return {
    type: types.FETCH_BUYINGPOWER, 
    email
  };
}

export function fetchBuyingPowerRes(data) {  
  return {
    type: types.FETCH_BUYINGPOWER_RES, 
    data
  };
}

export function getBuyingPower(email) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      buyingPowerApi.fetchBuyingPower(email).then(data => {
        dispatch(fetchBuyingPowerRes(data));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}

