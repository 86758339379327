import * as types from './actionTypes';  
import userBouncesApi from '../api/userBouncesApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doUserBounces(ext_id) {

  return {
    type: types.DO_USER_BOUNCES, 
    ext_id
  };
}

export function doUserBouncesRes(data) {  
  return {
    type: types.DO_USER_BOUNCES_RES, 
    data
  };
}

export function getUserBounces(ext_id) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      userBouncesApi.doUserBounces(ext_id).then(data => {
        dispatch(doUserBouncesRes(data));
        dispatch(doUserBouncesRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}

export function doUserBounceDetail(url) {

  return {
    type: types.DO_USER_BOUNCES_DETAIL, 
    url
  };
}

export function doUserBounceDetailRes(data) {  
  return {
    type: types.DO_USER_BOUNCES_DETAIL_RES, 
    data
  };
}

export function getUserBounceDetail(url) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      userBouncesApi.doUserBounceDetail(url).then(data => {
        dispatch(doUserBounceDetailRes(data));
        dispatch(doUserBounceDetailRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}

export function doResendUserBounces(ext_id) {

  return {
    type: types.DO_USER_RESEND_BOUNCES, 
    ext_id
  };
}

export function doResendUserBouncesRes(data) {  
  return {
    type: types.DO_USER_RESEND_BOUNCES_RES, 
    data
  };
}

export function resendUserBounces(ext_id) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      userBouncesApi.doResendUserBounces(ext_id).then(data => {
        dispatch(doResendUserBouncesRes(data));
        dispatch(doResendUserBouncesRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}

export function doPrintUserBounces(ext_id) {

  return {
    type: types.DO_USER_PRINT_BOUNCES, 
    ext_id
  };
}

export function doPrintUserBouncesRes(data) {  
  return {
    type: types.DO_USER_PRINT_BOUNCES_RES, 
    data
  };
}

export function printUserBounces(ext_id) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      userBouncesApi.doPrintUserBounces(ext_id).then(data => {
        dispatch(doPrintUserBouncesRes(data));
        dispatch(doPrintUserBouncesRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}