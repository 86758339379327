import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './offeringCallBacks.scss';
import Dashboard from '../../component/Dashboard';
import { getOfferingCallBack, doOfferingCallBackRes } from '../../actions/offeringCallBacksActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import GridTable from '@nadavshaar/react-grid-table';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
let isCallBack = false;
class OfferingCallBacksComponent extends React.PureComponent {

	constructor(props){
		super(props);
		isCallBack = false;
		const columns = [
			{ id: 1, width: '20%', field: 'verb', label: 'Verb', visible: true, sortable: true, cellRenderer: this.verbTemp },
			{ id: 2, width: '20%', field: 'created_at', label: 'Sent At', visible: true, sortable: true, cellRenderer: this.verbTemp },
			{ id: 3, width: '20%', field: 'tenant_callback', label: 'Sent To', visible: true, sortable: true, cellRenderer: this.verbTemp },
			{ id: 4, width: '20%', field: 'payload', label: 'Content', visible: true, sortable: true, cellRenderer: this.verbTemp },
			{ id: 5, width: '20%', field: 'state', label: 'Response', visible: true, sortable: true, cellRenderer: this.verbTemp }
		];
		this.state = {
			cols: columns,
			offeringDetail: {},
			offeringCallBacks: [],
			pagenum: 1,
			lastofferingCallBacks: 0,
			offeringCallBacksLength: 0,
			isFetchOfferingCallBack: false

		}
	}
	componentDidMount() {
		let offering = this.props.location.state.offeringInfo;
		this.setState({
			offeringDetail: offering
		}, () => {
			let request = {
				ext_id: offering.ext_id,
				pagenum: this.state.pagenum,
			}
			isCallBack = true;
			this.props.getOfferingCallBack(request);
		});
	}

	static getDerivedStateFromProps(props, state) {
		if (props.offeringCallBackRes) {
			if (props.offeringCallBackRes.data && props.offeringCallBackRes.data.offeringCallBacks) {
				if (props.offeringCallBackRes.data.offeringCallBacks.message === 'Success' && isCallBack) {
					isCallBack = false;
					let arrData = props.offeringCallBackRes.data.offeringCallBacks.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };

					let isOfferingCallBackLength = state.offeringCallBacksLength;
					if (state.offeringCallBacksLength === 0) {
						isOfferingCallBackLength = arrData.length;
					}
					if (state.isFetchOfferingCallBack) {
						return {
							lastofferingCallBacks: arrData.length,
							offeringCallBacksLength: isOfferingCallBackLength,
							offeringCallBacks: state.offeringCallBacks.concat(arrData),
							isFetchOfferingCallBack: false
						};
					} else {
						return {
							lastofferingCallBacks: arrData.length,
							offeringCallBacksLength: isOfferingCallBackLength,
							offeringCallBacks: arrData,
						};
					}
				}
			}
		}
		return null;
	}
	goBackNavigate = () => {
		this.props.history.goBack();
	}
	
	verbTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (value) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex'}}>
					<Tooltip title={value !== undefined ? value : ''} interactive >
						<div className="less-text" style={{width: '90%'}}>{value}</div>
					</Tooltip>
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button color="primary" style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
					</Tooltip>
				</div>
			)
		}
	}
	loadMore = () => {
		if (this.state.lastofferingCallBacks === this.state.offeringCallBacksLength) {
			let request = {
				ext_id: this.state.offeringDetail.ext_id,
				pagenum: this.state.pagenum + 1,
			}
			isCallBack = true;
			this.props.getOfferingCallBack(request);
			this.setState({
				pagenum: request.pagenum,
				isFetchOfferingCallBack: true
			});
		}
	}
	
    showAll = () => {
        this.setState({
            offeringCallBacks: []
        }, () => {
			let request = {
				ext_id: this.state.offeringDetail.ext_id,
				pagenum: -1
			}
            isCallBack = true;
			this.props.getOfferingCallBack(request);
            this.setState({
				pagenum: request.pagenum,
				isFetchOfferingCallBack: true
			});
        })
        
    }
	downloadFile = async () => {
		const { offeringCallBacks } = this.state;
		const fileName = "response";
		const json = JSON.stringify(offeringCallBacks);
		const blob = new Blob([json],{type:'application/json'});
		const href = await URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = href;
		link.download = fileName + ".json";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
	render() {
		const { offeringCallBacks, offeringDetail } = this.state;

		let headers = <div className="row">
			<div className="col-md-4">
				<div className="offeringDetailSubheader">
					<div className="col-sm-12 offeringBouncestop_bar">
						{(offeringDetail && offeringDetail.name) &&
							<div className="callback-back">
								<div onClick={() => { this.goBackNavigate(); }}>
									<ArrowBackIosIcon /> {offeringDetail ? offeringDetail.name : ''}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
			<div className="col-md-4">
				<div className="offering-callback-title">CallBacks List</div>
			</div>
			<div className="col-md-4" style={{textAlign: 'right'}}>
				<Button variant="contained" color="primary" style={{ marginRight: 8, marginBottom: 8 }} disabled={offeringCallBacks.length === 0} onClick={() => this.downloadFile()}>Download JSON</Button>
			</div>
		</div>

		return (
			<Dashboard title="ClickIPO Offerings" pageTitle="Offering CallBacks" >
				<div className="offering_callback_content">
					<div>
						{headers}
						{
							<div className="col-md-12 offering-callback-table">
								<div style={{ marginTop: 30 }}>
									<GridTable
										columns={this.state.cols}
										rows={offeringCallBacks}
										isPaginated={false}
										isVirtualScroll={true}
										showSearch={true}
										showRowsInformation={false}
										isHeaderSticky={true}
									/>
								</div>
							</div>
						}
						<div className="col-md-12 mt-3">
							{
								(this.state.lastofferingCallBacks === this.state.offeringCallBacksLength) && offeringCallBacks.length !== 0 ?
									<Button variant="contained" color="primary" onClick={() => this.loadMore()} style={{ marginRight: 8, marginBottom: 8 }} disabled={this.state.pagenum === -1}>Load more</Button>
									:
									<Button variant="contained" color="primary" style={{ marginRight: 8, marginBottom: 8 }} disabled={true}>Load more</Button>
							}
							<Button variant="contained" color="primary" onClick={() => this.showAll()} style={{ marginRight: 8, marginBottom: 8 }} >Load all</Button>
						</div>
					</div>
				</div>
			</Dashboard>
		)
	}
}

OfferingCallBacksComponent.propTypes = {
	offeringCallBackRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	offeringCallBackRes: doOfferingCallBackRes,
});

function mapDispatchToProps(dispatch) {
	return {
		getOfferingCallBack: (ext_id) => dispatch(getOfferingCallBack(ext_id)),
		getOfferingCallBackRes: (data) => dispatch(doOfferingCallBackRes(data)),
		
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingCallBacksComponent);
