import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Swal from 'sweetalert2';
import './offeringInvestors.scss';
import Dashboard from '../../component/Dashboard';
import { getOfferingDetail, doOfferingDetailRes } from '../../actions/offeringDetailActions';
import { getCustomerList, doCustomerListRes } from '../../actions/customerListActions';
import { getBuyingPower, fetchBuyingPowerRes } from '../../actions/buyingPowerActions';
import { submitDeleteOrder, doDeleteOrderRes } from '../../actions/deleteOrderActions';
import { sendUpdateReqAmount, doUpdateReqAmountRes } from '../../actions/updateRequestedAmountActions';
import { fetchOrdersMetaData, doFetchOrdersMetadataRes } from '../../actions/ordersMetaDataActions';
import Button from '@material-ui/core/Button';
import { numberWithCommasAndDollar, convertDateFormat, numberWithCommas, getOfferingPrice, getPriceText, getDate,  } from '../../helper/helper';
import GridTable from '@nadavshaar/react-grid-table';
import TextField from '@material-ui/core/TextField';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MailIcon from '@material-ui/icons/Mail';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getItem } from '../../utils/localStore';
import NumberFormat from 'react-number-format';
import { DialogActions } from '@material-ui/core';
const DialogTitle = withStyles(theme => ({
    root: {
      margin: 0,
	  padding: theme.spacing(2),
	  position: 'relative'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<div className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</div>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
let isBuyingPower = false;
let isDeleteOrder = false;
let isUpdateAmount = false;
let isCustomer = false;
let isOffDetail = false;
let isOrdersMetaData = false;
class OfferingInvestorsComponent extends React.PureComponent {

	constructor(props){
		super(props);
		isBuyingPower = false;
		isDeleteOrder = false;
		isUpdateAmount = false;
		isCustomer = false;
		isOffDetail = false;
		isOrdersMetaData = false;
		let bdMpid = getItem('mpid');
		var columns = [
			{ id: 1, field: 'name', label: 'Name', visible: true, sortable: false, width: '200px', cellRenderer: this.fullNametemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 2, field: 'account_name', label: 'Account', visible: true, sortable: false, width: '200px', cellRenderer: this.accountTemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 3, field: 'email', label: 'Email', visible: true, sortable: false, width: '250px', cellRenderer: this.emailTemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 4, field: 'created_at', label: 'Date', visible: true, sortable: false, width: '200px', headerCellRenderer: this.orderCustomHeader },
			{ id: 5, field: 'buying_power_as_of', label: 'IPO Buying Power', visible: true, sortable: false, width: '250px', cellRenderer: this.buyingPowertemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 6, field: 'available_balance', label: 'Account Balance', visible: true, sortable: false, width: '250px', cellRenderer: this.accountBalanceTemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 7, field: 'requested_amount', label: 'Amount Requested', visible: true, sortable: false, width: '250px', cellRenderer: this.requestedAmounttemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 8, field: 'allocated_amount', label: 'Amount Allocated', visible: true, sortable: false, width: '250px', cellRenderer: this.allocatedAmountTemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 9, field: 'requested_shares', label: 'Shares Requested', visible: true, sortable: false, width: '250px', cellRenderer: this.sharesRequestedTemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 10, field: 'allocated_shares', label: 'Shares Allocated', visible: true, sortable: false, width: '250px', cellRenderer: this.sharesAllocatedTemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 11, field: 'status', label: 'Status', visible: true, sortable: false, width: '150px', cellRenderer: this.statustemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 12, field: 'initial_prospectus_datetime', visible: true, sortable: false, label: 'Initial Prospectus', width: '250px', cellRenderer: this.initialProspectusTemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 13, field: 'final_prospectus_datetime', visible: true, sortable: false, label: 'Final Prospectus', width: '250px', cellRenderer: this.finalProspectusTemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 14, field: 'attestation', visible: true, sortable: false, label: 'Attestation', width: '250px', cellRenderer: this.finalProspectusTemplate, headerCellRenderer: this.orderCustomHeader },
			{ id: 15, field: 'communication', label: 'Comms', visible: true, sortable: false, width: '130px', cellRenderer: this.communicationtemplate },
		]
		this.state = {
			offeringDetail: '',
			customerList: [],
			userEmail: '',
			offId: '',
			cols: columns,
			multiSelect: [
				{ label: 'Active', value: true, id: 1 },
				{ label: 'Closed', value: false, id: 2 },
				{ label: 'Cancelled', value: false, id: 3 }
			],
			globalFilter: '',
			orderInfo: null,
			buyingPowerOffering: null,
			isGreaterBuyingPower:null,
			isLessMinBuyingPower:null,
			isEditModel: false,
			tableFilter: {
				field: null,
				order: null
			},
			offeringId: '',
			offeringStatus: '',
			pagenum: 1,
			searching: '',
			filterBy: 'name',
			isFetchCustomer: false,
			lastCustomerList: 0,
			customerListLength: 0,
			ordersMetaData: null,
			mpid: bdMpid,
			updatedAmount: '',
			updatedShares: '',
			reason: '',
			showDeleteModal: false,
			offeringExtId: '',
			userDeleteEmail: '',
			userAccountId: '',
		}
	}
	componentDidMount() {
		if (this.props.location.state) {
			if (this.props.location.state.offeringStatus) {
				let offStatus = this.props.location.state.offeringStatus;
				this.setState({
					offeringStatus: offStatus === 'effective' ? 'closed' : offStatus,
					offeringId: this.props.match.params.id
				})
			}
		}
		if (this.props.location.state && this.props.location.state.email) {
			this.setState({
				userEmail: this.props.location.state.email,
			});
		} else {
			this.setState({
				userEmail: '',
			});
		}
		this.props.getOfferingDetail(this.props.match.params.id);
		this.props.fetchOrdersMetaData(this.props.match.params.id);
		isOffDetail = true;
		isOrdersMetaData = true;
		let OfferingId = this.props.match.params.id;
		this.setState({
			offId: OfferingId,
		});
		let payloadReq = {
			ext_id: this.props.match.params.id,
			status: this.props.location.state.offeringStatus === 'effective' ? 'closed' : this.props.location.state.offeringStatus ,
			pagenum: this.state.pagenum
		}
		if (this.props.location.state.email && this.props.location.state.email !== null) {
			payloadReq.email = this.props.location.state.email
		}
		isCustomer = true;
		this.props.getCustomerList(payloadReq);
	}
	
	static getDerivedStateFromProps(props, state) {
		if (props.fetchOrdersMetadataRes) {
			if (props.fetchOrdersMetadataRes.data && props.fetchOrdersMetadataRes.data.ordersMetaData) {
				if (props.fetchOrdersMetadataRes.data.ordersMetaData.message === 'Success'  && isOrdersMetaData) {
					return {
						ordersMetaData: props.fetchOrdersMetadataRes.data.ordersMetaData.data
					}
				}
			}
		}
		if (props.offeringDetailRes) {
			if (props.offeringDetailRes.data && props.offeringDetailRes.data.offeringdetail) {
				if (props.offeringDetailRes.data.offeringdetail.error === 0 && isOffDetail) {
					isOffDetail = false;
					return {
						offeringDetail: props.offeringDetailRes.data.offeringdetail.data
					};
				}
			}
		}
		if (props.customerListRes) {
			if (props.customerListRes.data && props.customerListRes.data.customerList) {
				if (props.customerListRes.data.customerList.error === 0 && isCustomer) {
					isCustomer = false;
					let isCustomerListLength = state.customerListLength;
					if (state.customerListLength === 0) {
						isCustomerListLength = props.customerListRes.data.customerList.data.length
					}
					if (state.isFetchCustomer) {
						return {
							lastCustomerList: props.customerListRes.data.customerList.data.length,
							customerListLength: isCustomerListLength,
							customerList: state.customerList.concat(props.customerListRes.data.customerList.data),
							isFetchCustomer: false
						};
					} else {
						return {
							lastCustomerList: props.customerListRes.data.customerList.data.length,
							customerListLength: isCustomerListLength,
							customerList: props.customerListRes.data.customerList.data
						};
					}
					
				}
			}
		}
		if (props.fetchBuyingPowerRes) {
			if (props.fetchBuyingPowerRes.data && props.fetchBuyingPowerRes.data.buyingPower) {
				if (props.fetchBuyingPowerRes.data.buyingPower.error === 0 && isBuyingPower) {
					isBuyingPower = false;
					return {
						buyingPowerOffering: props.fetchBuyingPowerRes.data.buyingPower.data
					};
				}
			}
		}
		if (props.deleteOrderRes) {
			if (props.deleteOrderRes.data && props.deleteOrderRes.data.deleteOrder) {
				if (props.deleteOrderRes.data.deleteOrder.message === 'Success'  && isDeleteOrder) {
					isDeleteOrder = false;
					let payloadReq = {
						ext_id: state.offId,
						status: state.offeringStatus,
						pagenum: state.pagenum
					}
					if (state.userEmail !== '') {
						payloadReq.email = state.userEmail
					}
					isCustomer = true;
					props.getCustomerList(payloadReq);
					return {
						reason: '',
						showDeleteModal: false,
						offeringExtId: '',
						userDeleteEmail: '',
						userAccountId: '',
						isEditModel: false,
						orderInfo: null,
						buyingPowerOffering: null
					}
				}
			}
		}
		if (props.updateReqAmountRes) {
			if (props.updateReqAmountRes.data && props.updateReqAmountRes.data.updateRequestedAmount) {
				if (props.updateReqAmountRes.data.updateRequestedAmount.error === 0  && isUpdateAmount) {
					isUpdateAmount = false;
					let payloadReq = {
						ext_id: state.offId,
						status: state.offeringStatus,
						pagenum: state.pagenum
					}
					if (state.userEmail !== '') {
						payloadReq.email = state.userEmail
					}
					isCustomer = true;
					props.getCustomerList(payloadReq);
				}
			}
		}
		return null;
	}
	orderCustomHeader = ({tableManager, column, mode, ref, checked, disabled, indeterminate, onChange}) => {
        return <div>{column.label} {this.onSortFunction(column.field)}</div>
    }

	goBackNavigate = () => {
		this.props.history.goBack();
	}
	goDetail = (inverstorInfo) => {
		this.props.history.push({ pathname: `/investor-detail`, state: { multiData: this.state.multiSelect, email: inverstorInfo.email } });
	}

	export = () => {
		let csvContent = 'data:text/csv;charset=utf-8,';
			csvContent += 'Name, Account, Email, Date, IPO Buying Power, Account Balance, Amount Requested, Amount Allocated, Shares Requested, Shares Allocated, Status, Initial Prospectus, Final Prospectus \r\n';
			this.state.customerList.forEach(function (rowArray) {
				const newRow = rowArray.name + ',' + rowArray.account_name + ',' + rowArray.email + ',' + rowArray.created_at + ',' + rowArray.buying_power_as_of + ',' + rowArray.available_balance + ',' + rowArray.requested_amount + ',' + rowArray.allocated_amount + ',' + rowArray.requested_shares + ',' + rowArray.allocated_shares + ',' + rowArray.status + ',' + rowArray.initial_prospectus_datetime + ',' + rowArray.final_prospectus_datetime;
				csvContent += newRow + '\r\n'; // add carriage return
			});
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'customerList.csv');
		document.body.appendChild(link); // Required for FF
		link.click();
	}

	fullNametemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner green-color-with-pointer" onClick={() => this.goDetail(data)}>{data.name} </div>;
	}

	statustemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		if (this.state.offeringStatus === 'cancelled') {
			return <div className="rgt-cell-inner" style={{ color: '#FF0000' }}> {this.state.offeringStatus} </div>;
		} else {
			return <div className="rgt-cell-inner"> {this.state.offeringStatus} </div>;
		}
	}

	requestedAmounttemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		if (this.state.offeringStatus === 'active') {
			return <div className="rgt-cell-inner">
				{
					this.state.offeringDetail && this.state.offeringDetail.ordr_dollar_share === 0 ? 
					<div style={{ cursor: 'pointer', color: '#689F38', fontWeight: 'bold' }} onClick={() => { this.openEditAmountModel(data); }}>
						{numberWithCommasAndDollar(data.requested_amount)}
					</div>
					:
					<div>
						{numberWithCommasAndDollar(data.requested_amount)}
					</div>
				}
				
			</div>;
		} else {
			return <div className="rgt-cell-inner"> {numberWithCommasAndDollar(data.requested_amount)} </div>;
		}
	}

	communicationtemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner"> <Button variant="contained" color="primary" onClick={() => { this.openModel(data); }}><MailIcon /></Button> </div>;
	}
	openModel = (user) => {
		this.props.history.push({
			pathname: '/offering-communication',
			state: { offeringInfo: user, offeringId: this.state.offId }
		})
	}
	buyingPowertemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner">{numberWithCommasAndDollar(parseFloat(data.buying_power_as_of))} </div>;
	}

	allocatedAmountTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner"> {numberWithCommasAndDollar(data.allocated_amount)} </div>;
	}

	accountTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner">{data.account_name} </div>;
	}

	emailTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner"> {data.email} </div>;
	}

	sharesAllocatedTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner"> 
			{data.allocated_shares}
		</div>;
	}

	sharesRequestedTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner">
		
			{
				this.state.offeringDetail && this.state.offeringDetail.ordr_dollar_share === 1 ? 
				<div style={{ cursor: 'pointer', color: '#689F38', fontWeight: 'bold' }} onClick={() => { this.openEditAmountModel(data); }}>
					{numberWithCommas(data.requested_shares)}
				</div>
				:
				<div>
					{numberWithCommas(data.requested_shares)}
				</div>
			}
		</div>;
	}

	finalProspectusTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner"> {value} </div>;
	}

	initialProspectusTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner"> {data.initial_prospectus_datetime} </div>;
	}

	attestationTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner"> {data.attestation}</div>;
	}

	accountBalanceTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		// Varun -> show account_balance - requested_amount . This is not correct
		let bal = 0;
		if (data.available_balance != null) {
			//bal = parseFloat(data.available_balance) - parseFloat(data.requested_amount);
			bal = parseFloat(data.available_balance);
		} else {
			bal = 0.00;
		}
		return <div className="rgt-cell-inner"> {numberWithCommasAndDollar(bal)} </div>;
	}
	openEditAmountModel = (order) => {
		let amountShare = '';
		if (this.state.offeringDetail.ordr_dollar_share === 1) {
			amountShare = order.requested_shares
		}
		this.setState({
			isEditModel: !this.state.isEditModel,
			orderInfo: order,
			updatedShares: amountShare
		});
		this.props.getBuyingPower(order.email);
		isBuyingPower = true;
	}

	closeEditmodel = () => {
		this.setState({
			isEditModel: !this.state.isEditModel,
			orderInfo: null,
			buyingPowerOffering: null
		});
	}
	
	handleChange = (event) => {
		if (this.state.buyingPowerOffering && this.state.orderInfo.requested_amount !== parseInt(event.target.value)) {
			if (parseInt(event.target.value) > parseInt(this.state.buyingPowerOffering.power.buying_power)) {
				this.setState({
					isFormValid: true,
					updatedAmount: event.target.value,
					isGreaterBuyingPower: true
				});
			}
			if (parseInt(event.target.value) < parseInt(this.state.buyingPowerOffering.power.bd_min_required)) {
				this.setState({
					isFormValid: true,
					isLessMinBuyingPower: true
				});
			}
			if ((parseInt(event.target.value) < parseInt(this.state.buyingPowerOffering.power.buying_power)) && (parseInt(event.target.value) >= parseInt(this.state.buyingPowerOffering.power.bd_min_required))) {
				this.setState({
					isFormValid: true,
					updatedAmount: event.target.value,
					isGreaterBuyingPower: false,
					isLessMinBuyingPower: false
				});
			}
		} else {
			this.setState({
				isFormValid: false,
				isGreaterBuyingPower: false,
				isLessMinBuyingPower: false
			})
		}

	}
	handleChangeShare = (value) => {
		if (this.state.buyingPowerOffering && this.state.orderInfo.requested_share !== parseInt(value)) {
			let minPrice = this.state.offeringDetail.min_price;
			let minBd = this.state.buyingPowerOffering.power.bd_min_required;
			let minBdShare = parseInt(minBd) / parseInt(minPrice);
			let buyPower = this.state.buyingPowerOffering.power.buying_power;
			let buyPowerShare = parseInt(buyPower) / parseInt(minPrice);
			if (parseInt(value) > parseInt(buyPowerShare)) {
				this.setState({
					isFormValid: true,
					updatedShares: value,
					isGreaterBuyingPower: true
				});
			}
			if (parseInt(value) < parseInt(minBdShare)) {
				this.setState({
					isFormValid: true,
					isLessMinBuyingPower: true
				});
			}
			if ((parseInt(value) < parseInt(buyPowerShare)) && (parseInt(value) >= parseInt(minBdShare))) {
				this.setState({
					isFormValid: true,
					updatedShares: value,
					isGreaterBuyingPower: false,
					isLessMinBuyingPower: false
				});
			}
		} else {
			this.setState({
				isFormValid: false,
				isGreaterBuyingPower: false,
				isLessMinBuyingPower: false
			})
		}
	}
	updateAmount = (ext_id, email, account_id, buying_power) => {
		let payload = {
			ext_id: ext_id,
			email: email,
			mpid: this.state.mpid, 
			account_id: account_id, 
			buying_power: buying_power
		}
		if (this.state.offeringDetail.ordr_dollar_share === 0) {
			payload.requested_amount = parseInt(this.state.updatedAmount)
		}
		if (this.state.offeringDetail.ordr_dollar_share === 1) {
			payload.requested_amount = parseInt(this.state.updatedShares)
		}
		this.props.sendUpdateReqAmount(payload);
		isUpdateAmount = true;
		// this.closeEditmodel();
	}
	openDeleteModal = (offering_ext_id, email, account_id) => {
		this.setState({
			offeringExtId: offering_ext_id,
			userDeleteEmail: email,
			userAccountId: account_id,
			showDeleteModal: true
		});
	}
	closeDeleteModal = () => {
		this.setState({
			showDeleteModal: false,
			reason: '',
			offeringExtId: '',
			userDeleteEmail: '',
			userAccountId: '',
		});
	}
	deleteOrder = () => {
		
		if (this.state.reason !== '') {
			let req = {
				ext_id: this.state.offeringExtId,
				email: this.state.userDeleteEmail,
				mpid: this.state.mpid, 
				account_id: this.state.userAccountId,
				reason: this.state.reason
			}
			isDeleteOrder = true;
			this.props.submitDeleteOrder(req);
			// this.closeEditmodel();
		} else {
			Swal.fire({
				title: 'Please enter reason',
				icon: 'info',
				confirmButtonText: 'OK',
				allowOutsideClick: false,
				timer: 30000000,
				heightAuto: false
			});
		}
	}
	changeOfferingStatus = (e) => {
		this.setState({
			offeringStatus: e.target.value
		}, () => {
			this.setState({
				tableFilter: {
					field: null,
					order: null
				},
				searching: '',
				pagenum: 1,
			})
			let request = {
				ext_id: this.state.offeringId,
				status: this.state.offeringStatus,
				pagenum: 1
			}
			if (this.state.userEmail !== '') {
				request.email = this.state.userEmail;
			}
			this.props.getCustomerList(request);
			isCustomer = true;
		})
	}
	fetchData = () => {
        if (this.state.lastCustomerList === this.state.customerListLength) {
			let payload = {
				ext_id: this.state.offeringId,
				status: this.state.offeringStatus,
				pagenum: this.state.pagenum + 1
			}
			if (this.state.userEmail !== '') {
				payload.email = this.state.userEmail;
			}
			this.setState({
				isFetchCustomer: true,
				pagenum: this.state.pagenum + 1
			})
			isCustomer = true;
			this.props.getCustomerList(payload);
        }
	}
	onSortFunction(field) {
        return (<span>
			{
				((this.state.tableFilter.field !== field)) &&
				<button className="sort-button sort-button-rotate" onClick={() => { this.doSort(field, 'asc'); }}><i className="fa fa-exchange" aria-hidden="true"></i></button>
			}
			{
				((this.state.tableFilter.order === 'asc') && (this.state.tableFilter.field === field)) &&
				<button className="sort-button" onClick={() => { this.doSort(field, 'desc'); }}><i className="fa fa-sort-amount-asc" aria-hidden="true"></i></button>
			}
			{
				((this.state.tableFilter.order === 'desc') && (this.state.tableFilter.field === field)) &&
				<button className="sort-button" onClick={() => { this.doSort(field, 'asc'); }}><i className="fa fa-sort-amount-desc" aria-hidden="true"></i></button>
			}
        </span>);
    }
	doSort(field, order) {
        if (this.state.customerList) {
            if (order === null || order === 'asc') {
                this.setState({
                    isSort: 'desc',
                    tableFilter: {
                        field,
                        order,
                    },
                }, () => { });
                let payload = {
					ext_id: this.state.offeringId,
					status: this.state.offeringStatus,
                    pagenum: 1,
                    sortby : field,
                    sorttype: order
				}
				if (this.state.userEmail !== '') {
					payload.email = this.state.userEmail;
				}
                if (this.state.searching !== '') {
                    payload.search_key = this.state.filterBy;
                    payload.search_keyword = this.state.searching;
				}
				isCustomer = true;
                this.props.getCustomerList(payload);
                this.setState({
                    pagenum: 1
                })
            } else {
                this.setState({
                    isSort: 'asc',
                    tableFilter: {
                        field,
                        order,
                    },
                }, () => { });
                let payload = {
                    ext_id: this.state.offeringId,
					status: this.state.offeringStatus,
                    pagenum: 1,
                    sortby : field,
                    sorttype: order,
				}
				if (this.state.userEmail !== '') {
					payload.email = this.state.userEmail;
				}
                if (this.state.searching !== '') {
                    payload.search_key = this.state.filterBy;
                    payload.search_keyword = this.state.searching;
				}
				isCustomer = true;
                this.props.getCustomerList(payload);
                this.setState({
                    pagenum: 1
                })
            }
        }
	}
	doRefreshOrder = () => {
		let request = {
			ext_id: this.state.offId,
			status: this.state.offeringStatus,
			pagenum: 1,
		}
		if (this.state.userEmail !== '') {
			request.email = this.state.userEmail;
		}
        if (this.state.tableFilter.field !== null) {
            request.sortby = this.state.tableFilter.field;
            request.sorttype = this.state.tableFilter.order;
		}
		this.setState({pagenum: 1})
		isCustomer = true;
        this.props.getCustomerList(request);
	}
	handleBlur = () => {
		if (this.state.searching === '') {
			this.doRefreshOrder();
		}
	}
	searchOrder = (event) => {
		this.setState({
			searching: event.target.value,
		});
	}
	doSearchOrder = () => {
		if (this.state.searching !== '') {
			let request = {
                ext_id: this.state.offId,
				status: this.state.offeringStatus,
                pagenum: 1,
                search_key: this.state.filterBy,
                search_keyword: this.state.searching
            }
			if (this.state.userEmail !== '') {
                request.email = this.state.userEmail;
			}
            if (this.state.tableFilter.field !== null) {
                request.sortby = this.state.tableFilter.field;
                request.sorttype = this.state.tableFilter.order;
			}
			isCustomer = true;
            this.props.getCustomerList(request);
            this.setState({
                pagenum: 1
            })
		}
	}
	loadMore = () => {
		this.fetchData();
    }
	render() {
		const { customerList, offeringDetail, orderInfo, buyingPowerOffering, isGreaterBuyingPower, isLessMinBuyingPower, ordersMetaData } = this.state;
		let headers =   <div className="row">
			
			<div className="col-md-2">
				<FormControl style={{width: 150, textAlign: 'left'}}>
					<Select
					labelId="Offering-label"
					id="offering"
					value={this.state.offeringStatus}
					onChange={this.changeOfferingStatus}
					>
						<MenuItem value={'active'}>Active</MenuItem>
						<MenuItem value={'closed'}>Closed</MenuItem>
						<MenuItem value={'cancelled'}>Cancelled</MenuItem>
					</Select>
				</FormControl>
			</div>
			<div className="col-md-2">
				<Button variant="contained" color="primary" fullWidth={true} onClick={this.export}>Download CSV</Button>
			</div>
			<div className="col-md-2">
				<div className="bouncelist-search">
					{/* <TextField type="text" placeholder="Search" onInput={(e) => this.setState({globalFilter: e.target.value})}/> */}
					<TextField 
							id="searchInput" 
							type="text" 
							placeholder="Search" 
							value={this.state.searching} 
							onChange={this.searchOrder}
							onBlur={this.handleBlur}
							InputProps={{
								endAdornment: (
									  <InputAdornment position="start">
										<Button variant="contained" color="primary" style={{borderRadius: '0px 4px 4px 0px', minWidth: 50, top: 19, right: 0, left: 8, height: 38}} onClick={() => this.doSearchOrder()} disabled={this.state.searching !== '' ? false : true}><SearchIcon /></Button>
									  </InputAdornment>
								)
							}}
						/>
				</div>
			</div>
        </div>

		if (customerList) {
			for (let items of customerList) {
				
				if (items.created_at !== null) {
					items.attestation = items.created_at;
				} else {
					items.attestation = 'Not Available';
				}
				items.communication = '';

				items.order_date1 = convertDateFormat(items.created_at);

				items.fullname = items.name;
			}
		}

		// customerFilter = this.state.searching ? customerFilter.filter((row) => row.fullname.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1) : customerFilter;
		// let totalRequestedAmount = 0;
		// let totalAllocatedAmount = 0;
		// let totalAllocatedShare = 0;
		// if (customerFilter.length > 0) {
		//   for (const items of customerFilter) {
		// 	totalRequestedAmount = Number(totalRequestedAmount) + Number(items.requested_amount);
		// 	totalAllocatedAmount = Number(totalAllocatedAmount) + Number(items.allocated_amount);
		// 	totalAllocatedShare = Number(totalAllocatedShare) + Number(items.allocated_shares);
		//   }
		// }
		return (
			<Dashboard title="Offerings Investors" pageTitle="Offering Investors" >
				<div className="offering_investors_content">
					<div>
						<div className="row justify-content-md-center">
							<div className="col-md-4">
								<div className="offeringDetailSubheader">
								{(offeringDetail && offeringDetail.name) && 
									<div className="col-sm-12 offeringinvestors-top_bar">
									
										<div style={{textAlign: 'left'}}>
											<div className="callback-back">
												<ArrowBackIosIcon  onClick={() => { this.goBackNavigate(); }} /> {offeringDetail ? offeringDetail.name : ''}  {offeringDetail ? '(' + offeringDetail.ticker_symbol + ')' : ''}
											</div>
											<span className="sub">{getPriceText(offeringDetail)}: <span className="sub-info">{getOfferingPrice(offeringDetail)}</span>&nbsp;&nbsp;{offeringDetail.status === 'active' ? 'Anticipated: ' : 'Effective:'}
											{ offeringDetail.trade_date !== null && 
											<span className="sub-info"> {getDate(offeringDetail)} </span>
											}
											{ offeringDetail.trade_date === null && <span className="sub-info"> Not Available</span> }
											</span>
										</div>
										<div></div>
									</div>
									
								}
								</div>
							</div>
							<div className="col-md-8" >
								<table className={'tbl tbl-investors-footer'} style={{borderCollapse: 'collapse'}}>
									<thead>
										<tr>
											<th className="ta-left"><div>Total Requested Amount</div></th>
											<th className="ta-left"><div>Total Allocated Amount</div></th>
											<th className="ta-left"><div>Total Allocated Share </div></th>
											<th className="ta-left"><div>Total Number Of Customers </div></th>
										</tr>
									</thead>
									<tbody >
										<tr>
											<td className="ta-left"><div className="td-data">{numberWithCommasAndDollar(ordersMetaData && ordersMetaData.requested_amount ? ordersMetaData.requested_amount : 0)}</div></td>
											<td className="ta-left"><div className="td-data">{numberWithCommasAndDollar(ordersMetaData && ordersMetaData.allocated_amount ? ordersMetaData.allocated_amount : 0)}</div></td>
											<td className="ta-left"><div className="td-data">{Math.trunc(Math.abs(ordersMetaData && ordersMetaData.allocated_shares ? ordersMetaData.allocated_shares : 0))}</div></td>
											<td className="ta-left"><div className="td-data">{ordersMetaData && ordersMetaData.count ? ordersMetaData.count : 0}</div></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						{
							<div className="col-md-12">
								<div className="mt-4">{headers}</div>
								<div style={{ marginTop: 30 }}>
									<div className="offeringOrderListScroll">
										<div className="table-responsive">
											<GridTable
												columns={this.state.cols}
												rows={customerList}
												isPaginated={false}
												isVirtualScroll={true}
												showSearch={false}
												showRowsInformation={false}
												isHeaderSticky={true}
											/>
											<div className="col-md-12 mt-2">
												{
													(this.state.lastCustomerList === this.state.customerListLength) ?
													<Button variant="contained" color="primary" onClick={() => this.loadMore()} style={{ marginRight: 8, marginBottom: 8 }} disabled={this.state.pagenum === -1}>Load more</Button>
													:
													<Button variant="contained" color="primary" style={{ marginRight: 8, marginBottom: 8 }} disabled={true}>Load more</Button>
												}
											</div>
										</div>

									</div>
								</div>
								
								<Dialog
									onClose={this.closeEditmodel}
									aria-labelledby="customized-dialog-title"
									open={this.state.isEditModel}
									maxWidth={"sm"}
									fullWidth={true}
									scroll={'paper'}
								>
									<DialogTitle onClose={this.closeEditmodel} className="offering-detail-title"></DialogTitle>
									<DialogContent>
										{
											orderInfo &&
											<div className="col-md-12">
												<div className="requested-amount-edit-subtitle">{this.state.offeringDetail.name}</div>
												<div className="requested-amount-edit-title">{this.state.orderInfo.name}</div>
												<div className="row editModelText">
													<div className="col-md-12 editAmountModelbody">
														<div className="row">
														<div className="col-md-4 col-sm-4 no_paading">
															<p>Requested {this.state.offeringDetail.ordr_dollar_share === 0 ? 'Amount' : 'Shares'}</p>
															{this.state.offeringDetail.ordr_dollar_share === 0 ? 
																<TextField
																	id={orderInfo.id}
																	type="number"
																	defaultValue={parseFloat(orderInfo.requested_amount)}
																	className="ta-editable-textarea"
																	rows="1"
																	placeholder="After Changing To Submit"
																	style={{textAlign:"left",fontSize:16,fontWeight:900, width: '100%'}}
																	onChange={this.handleChange}
																/>
																:
																<NumberFormat
																	value={this.state.updatedShares}
																	thousandSeparator={false}
																	onValueChange={(values) => {
																		const { value } = values;
																		this.handleChangeShare(value)
																	}}
																	className="requested-share-change-input"
																	allowNegative={false}
																/>
															}
															
														</div>
														<div className="col-md-4 col-sm-4 no_paading">
															<p>IPO Buying Power <span></span></p>
															{buyingPowerOffering && buyingPowerOffering.power && <p> {numberWithCommasAndDollar(buyingPowerOffering.power.buying_power)} </p>}
														</div>
														<div className="col-md-4 col-sm-4 no_paading">
															<p>Min Buy Amount</p>
															{buyingPowerOffering && buyingPowerOffering.power &&<p>  ${buyingPowerOffering.power.bd_min_required} </p>}
														</div>

														</div>
													</div>
													</div>
													{
														this.state.offeringDetail && this.state.offeringDetail.ordr_dollar_share === 0 ?
														<div className="col-md-12 text_center">
															{isLessMinBuyingPower &&<p className="warning_message_Editmodal">Updated amount should be greater than minimum required buying amount</p>}
															{isGreaterBuyingPower &&<p className="warning_message_Editmodal"> Updated amount cannot be greater than the buying power </p>}

														</div>
														:
														<div className="col-md-12 text_center">
															{isLessMinBuyingPower &&<p className="warning_message_Editmodal">Updated share should be greater than minimum required buying amount</p>}
															{isGreaterBuyingPower &&<p className="warning_message_Editmodal"> Updated share cannot be greater than the buying power </p>}

														</div>
														
													}
													<div className="row" style={{marginTop: 20}}>
														<div className="col-md-6 col-sm-6" style={{textAlign: 'center'}}>
															<Button variant="contained" color="secondary" onClick={()=>{this.openDeleteModal(offeringDetail.ext_id ,orderInfo.email,orderInfo.account_id)}}>Cancel Order</Button>
														</div>
														<div className="col-md-6 col-sm-6" style={{textAlign: 'center'}}>
															<Button variant="contained" color="primary"
															disabled={(!this.state.isFormValid || isLessMinBuyingPower || isGreaterBuyingPower)}
															onClick={(e)=>{this.updateAmount(offeringDetail.ext_id, orderInfo.email, orderInfo.account_id, buyingPowerOffering.power.buying_power)}}>
																Modify
															</Button>
														</div>
													</div>
											</div>
										}
									</DialogContent>
								</Dialog>
								<Dialog
									onClose={this.closeDeleteModal}
									aria-labelledby="customized-dialog-title"
									open={this.state.showDeleteModal}
									maxWidth={"sm"}
									fullWidth={true}
									scroll={'paper'}
									>
									<DialogTitle onClose={this.closeDeleteModal} className="offering-detail-title">Delete order</DialogTitle>
									<DialogContent>
										<div className="col-md-12 editModelText" style={{textAlign: 'center'}}>
											<h5 className="mb-4">Are you sure you want to cancel this order ? please enter the reason for cancellation</h5>
											<div>
												<TextField type="text" label="Reason" variant="outlined" value={this.state.reason} multiline rowsMax={4} fullWidth onChange={(e) => this.setState({
													reason: e.target.value
												})} />
											</div>
										</div>
									</DialogContent>
									<DialogActions style={{display: 'block'}}>
										<div style={{textAlign: 'center'}}>
											<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.deleteOrder()}>
												Confirm
											</Button>
											<Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.closeDeleteModal()}>
												Cancel
											</Button>
										</div>
									</DialogActions>
								</Dialog>
							</div>
							
						}
					</div>
				</div>
			</Dashboard>
		)
	}
}

OfferingInvestorsComponent.propTypes = {
	offeringDetailRes: PropTypes.any,
	fetchOrdersMetadataRes: PropTypes.any,
	customerListRes: PropTypes.any,
	fetchBuyingPowerRes: PropTypes.any,
	deleteOrderRes: PropTypes.any,
	updateReqAmountRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	fetchOrdersMetadataRes: doFetchOrdersMetadataRes,
	offeringDetailRes: doOfferingDetailRes,
	customerListRes: doCustomerListRes,
	fetchBuyingPowerRes: fetchBuyingPowerRes,
	deleteOrderRes: doDeleteOrderRes,
	updateReqAmountRes: doUpdateReqAmountRes,
});

function mapDispatchToProps(dispatch) {
	return {
		getOfferingDetail: (ext_id) => dispatch(getOfferingDetail(ext_id)),
		fetchOrdersMetaData: (ext_id) => dispatch(fetchOrdersMetaData(ext_id)),
		getCustomerList: (data) => dispatch(getCustomerList(data)),
		getBuyingPower: (data) => dispatch(getBuyingPower(data)),
		submitDeleteOrder: (data) => dispatch(submitDeleteOrder(data)),
		sendUpdateReqAmount: (data) => dispatch(sendUpdateReqAmount(data)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingInvestorsComponent);
