import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './offeringCommunication.scss';
import Dashboard from '../../component/Dashboard';
import { getOfferingCommunication, doOfferingCommunicationRes } from '../../actions/offeringCommunicationActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import GridTable from '@nadavshaar/react-grid-table';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
let orderHistory = [];
let communication = [];
let orderActivities = [];
class OfferingCommunicationComponent extends React.PureComponent {

	constructor(props){
		super(props);
		const columns = [
			{ id: 1, field: 'title', label: 'Title', visible: true, sortable: true, cellRenderer: this.titleTemp },
			{ id: 2, field: 'body', label: 'Body', visible: true, sortable: true, cellRenderer: this.titleTemp },
			{ id: 3, field: 'created_at', label: 'Created At', visible: true, sortable: true, cellRenderer: this.titleTemp },
			{ id: 4, width: '250px', field: 'delivered_at', label: 'Delivered At', visible: true, sortable: true, cellRenderer: this.titleTemp },
			{ id: 5, width: '220px', field: 'opened_at', label: 'Opened At', visible: true, sortable: true, cellRenderer: this.titleTemp },
			{ id: 6, width: '210px', field: 'bounced_at', label: 'Bounced At', visible: true, sortable: true, cellRenderer: this.titleTemp },
			{ id: 7, width: '250px', field: 'bounce_message', label: 'Bounced Message', visible: true, sortable: true, cellRenderer: this.titleTemp },
			{ id: 8, width: '250px', field: 'type', label: 'Type', visible: true, sortable: true, cellRenderer: this.titleTemp }
		];
		this.state = {
			cols: columns,
			offeringCommunications: null,
			extId: '',
			offeringInfo: null,
            value: 0,
		}
	}
	componentDidMount() {
		if (this.props.location.state) {
			this.setState({
				extId: this.props.location.state.offeringId,
				offeringInfo: this.props.location.state.offeringInfo
			}, () => {
				let request = {
					ext_id: this.state.extId,
					email: this.state.offeringInfo.email
				}
				this.props.getOfferingCommunication(request);
			})
		}
		
	}

	static getDerivedStateFromProps(props, state) {
		if (props.offeringCommunicationRes) {
			if (props.offeringCommunicationRes.data && props.offeringCommunicationRes.data.offeringCommunication) {
				if (props.offeringCommunicationRes.data.offeringCommunication.error === 0) {
					let arrData = props.offeringCommunicationRes.data.offeringCommunication.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };
					return {
						offeringCommunications: arrData,
					};
				}
			}
		}
		return null;
	}
	goBackNavigate = () => {
		let req = {
			data: null,
			error: 0,
			message: "Success"
		}
		this.props.doOfferingCommunicationRes(req);
		
		this.props.history.goBack();
	}
	handleChange = (event, index) => {
		this.setState({ value: index });
	}
	titleTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (value) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex'}}>
					<Tooltip title={value !== undefined ? value : ''} interactive >
						<div className="less-text" style={{width: '90%'}}>{value}</div>
					</Tooltip>
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button color="primary" style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
					</Tooltip>
				</div>
			)
		}
	}
	render() {
		const { offeringCommunications, offeringInfo } = this.state;
		if (offeringCommunications) {
			orderHistory = offeringCommunications.order_history;
			communication = offeringCommunications.communications;
			if(orderHistory){
			  if(orderHistory.length !==0) {
				orderHistory = orderHistory.sort( (a, b) => {
				  return new Date(a.created_at) - new Date(b.created_at);
				});
			  }
				orderActivities=orderHistory.map((item,index)=>{
				  return (
					<div className="row"  key={index} style={{marginLeft:20, marginRight: 20, paddingBottom: '2%'}}>
						<div className="col-12 col-sm-12 orderhistory-titles-order">
						  {item.message}
						</div>
						<div className="row" >
						  <div className="col-12 col-sm-12 orderhistory-timestamp-order" style={{paddingLeft:30}}>
						  {item.created_at}</div>
						</div>
					  </div>
				  );
				});
			}
			else{
			  orderActivities=<div style={{marginLeft: 20, color:'#CACACA'}}>No Order History found</div>
			}
		}

		return (
			<Dashboard title="ClickIPO Offerings" pageTitle="Offering Communications" >
				<div className="offering_communication">
					<div>
						{
							<div className="col-md-12">
								<div>
									{
										offeringInfo && offeringInfo.name &&
										<div className="row">
											<div className="col-md-4">
												<div className="offeringDetailSubheader">
													<div className="col-sm-12 offeringBouncestop_bar">
													{(offeringInfo && offeringInfo.name) && 
														<div className="offering_communication-back">
															<div  onClick={() => { this.goBackNavigate(); }}>
																<ArrowBackIosIcon /> {offeringInfo ? offeringInfo.name : ''}
															</div>
														</div>
													}
													</div>
												</div>
											</div>
											<div className="col-md-4">
												<div className="offering-ommunication-title">{offeringInfo.email}</div>
											</div>
											<div className="col-md-4"></div>
										</div>
										
									}
								</div>
								<div style={{ marginTop: 30 }}>
									<Tabs value={this.state.value} onChange={this.handleChange}>
										<Tab label="Order History" />
										<Tab label="Communications" />
									</Tabs>
									{
									this.state.value === 0 &&
										<Typography component="div" style={{ padding: 8 * 3 }}>
											<div style={{ maxWidth: "100%" }}>
												{orderActivities}
											</div>
										</Typography>
									}
									{
										this.state.value === 1 &&
										<Typography component="div" style={{ padding: 8 * 3 }}>
											<div className="offering-communication-table" style={{ maxWidth: "100%" }}>
												<GridTable
													columns={this.state.cols}
													rows={communication}
													isPaginated={true}
													isVirtualScroll={true}
													showSearch={true}
													showRowsInformation={false}
													isHeaderSticky={true}
												/>
											</div>
										</Typography>
									}
								</div>
							</div>
						}
					</div>
				</div>
			</Dashboard>
		)
	}
}

OfferingCommunicationComponent.propTypes = {
	offeringCommunicationRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	offeringCommunicationRes: doOfferingCommunicationRes,
});

function mapDispatchToProps(dispatch) {
	return {
		getOfferingCommunication: (data) => dispatch(getOfferingCommunication(data)),
		doOfferingCommunicationRes: (data) => dispatch(doOfferingCommunicationRes(data)),
		
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingCommunicationComponent);
