import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './offerings.scss';
import { Link } from 'react-router-dom';
import Dashboard from '../../component/Dashboard';
import { getOffering, doOfferingRes, getOfferingMetaData, doOfferingMetaDataRes } from '../../actions/offeringActions';
import { numberWithCommasAndDollar} from '../../helper/helper';
import GridTable from '@nadavshaar/react-grid-table';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MailIcon from '@material-ui/icons/Mail';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@material-ui/icons/Cancel';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Tooltip from '@material-ui/core/Tooltip';

import FileCopyIcon from '@material-ui/icons/FileCopy';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
  
let isOffering = false;
class OfferingComponent extends React.Component {

	constructor(props) {
		super(props);
		isOffering = false;
		this.inputElement = null;
		var columns = [
			{id: 1, field: 'name', label: 'Name', visible: true, sortable: false, width: '250px', cellRenderer: this.offeringNameTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 2, field: 'offering_type_name', label: 'Type', visible: true, sortable: false, width: '150px', cellRenderer: this.offeringsTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 3, field: 'ticker_symbol', label: 'Symbol', visible: true, sortable: false, width: '150px', cellRenderer: this.offeringsTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 4, field: 'trade_date', label: 'Anticipated Date', visible: true, sortable: false, width: '250px', cellRenderer: this.offeringsTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 5, field: 'settlement_date', label: 'Effective Date', visible: true, sortable: false, width: '200px', cellRenderer: this.offeringsTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 6, field: 'final_price', label: 'Final Price', visible: true, sortable: false, width: '200px', cellRenderer: this.amountTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 7, field: 'min_price', label: 'Min Price', visible: true, sortable: false, width: '180px', cellRenderer: this.minPriceTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 8, field: 'max_price', label: 'Max Price', visible: true, sortable: false, width: '180px', cellRenderer: this.maxPriceTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 9, field: 'last_closing_price', label: 'Last Closing Price', visible: true, sortable: false, width: '220px', cellRenderer: this.lastClosingPriceTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 10, field: 'total_requested_investors', label: 'Req Investors Count', visible: true, sortable: false, width: '250px', cellRenderer: this.totalRequestedInvestors, headerCellRenderer: this.offeringCustomHeader},
			{id: 11, field: 'bounces', label: 'Email Bounces', visible: true, sortable: false, width: '200px', cellRenderer: this.bouncesTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 12, field: 'total_requested_amount', label: 'Req Investment Amount', visible: true, sortable: false, width: '280px', cellRenderer: this.amountTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 13, field: 'total_allocated_shares', label: 'Allocated Shares', visible: true, sortable: false, width: '230px', headerCellRenderer: this.offeringCustomHeader},
			{id: 14, field: 'total_allocated_investors', label: 'Allocated Investors Count', visible: true, sortable: false, width: '280px', headerCellRenderer: this.offeringCustomHeader},
			{id: 15, field: 'total_allocated_amount', label: 'Allocated Investment Amount', visible: true, sortable: false, width: '330px', cellRenderer: this.amountTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 16, field: 'average_order_size', label: 'Avg Allocated Order Size', visible: true, sortable: false, width: '280px', cellRenderer: this.offeringsTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 17, field: 'bd_underwriter_mpid_id', label: 'Underwriter', visible: true, sortable: false, width: '180px', cellRenderer: this.offeringsTemplate, headerCellRenderer: this.offeringCustomHeader},
			{id: 18, field: 'callbacks', label: 'Callback', visible: true, sortable: false, width: '150px', cellRenderer: this.callbacksTemplate},
		];
		this.state = {
			offeringTableView: [],
			offeringInfo: '',
			searching:'',
			cols: columns,
			fromdate:'',
			todate:'',
			multiSelect:[
				{status:'active', label:'Active', value: true, id: 1},
				{status:'effective', label:'Effective', value: false, id:4},
				{status:'cancelled', label:'Cancelled', value: false, id:3},
				{status:'closed', label:'Closed', value: false, id: 2},
			],
			openDetailModal: false,
			tableFilter: {
                field: null,
                order: null,
			},
			pagenum: 1,
			filterBy: 'name',
			selectedOfferingStatus: 'active',
			isFetchOffering: false,
			lastOfferingTableView: 0,
			offeringTableViewLength: 0,
			activeCount: 0,
			effectiveCount: 0,
			cancelledCount: 0,
			closedCount: 0,
			showOfferingTable: false
		}
		this.colOptions = [];
		for(let col of columns) {
			this.colOptions.push({label: col.header, value: col});
		}
	}

	componentDidMount() {
		this.props.getOfferingMetaData();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.offeringRes) {
			if (props.offeringRes.data && props.offeringRes.data.offering) {
				if (props.offeringRes.data.offering.message === "Success" && isOffering) {
					isOffering = false;
					let isOfferingTableViewLength = state.offeringTableViewLength;
					if (state.offeringTableViewLength === 0) {
						isOfferingTableViewLength = props.offeringRes.data.offering.data.length;
					}
					if (state.isFetchOffering) {
						return {
							lastOfferingTableView: props.offeringRes.data.offering.data.length,
							offeringTableViewLength: isOfferingTableViewLength,
							offeringTableView: state.offeringTableView.concat(props.offeringRes.data.offering.data),
							isFetchOffering: false
						};
					} else {
						return {
							lastOfferingTableView: props.offeringRes.data.offering.data.length,
							offeringTableViewLength: isOfferingTableViewLength,
							offeringTableView: props.offeringRes.data.offering.data,
						};
					}
					
				}
			}
		}
		if (props.offeringMetaDataRes) {
			if (props.offeringMetaDataRes.data && props.offeringMetaDataRes.data.offeringMetaData) {
				if (props.offeringMetaDataRes.data.offeringMetaData.message === "Success") {
					
					let offeringStatus = localStorage.getItem('setStatus');
					let selectedMultiSelect = [
						{status:'active', label:'Active', value: true, id: 1},
						{status:'effective', label:'Effective', value: false, id:4},
						{status:'cancelled', label:'Cancelled', value: false, id:3},
						{status:'closed', label:'Closed', value: false, id: 2},
					]
					if (offeringStatus) {
						if (offeringStatus === 'active') {
							selectedMultiSelect = [
									{status:'active', label:'Active', value: true, id: 1},
									{status:'effective', label:'Effective', value: false, id:4},
									{status:'cancelled', label:'Cancelled', value: false, id:3},
									{status:'closed', label:'Closed', value: false, id: 2},
								];
						} else if (offeringStatus === 'effective') {
							selectedMultiSelect = [
									{status:'active', label:'Active', value: false, id: 1},
									{status:'effective', label:'Effective', value: true, id:4},
									{status:'cancelled', label:'Cancelled', value: false, id:3},
									{status:'closed', label:'Closed', value: false, id: 2},
								];
						} else if (offeringStatus === 'cancelled') {
							selectedMultiSelect = [
									{status:'active', label:'Active', value: false, id: 1},
									{status:'effective', label:'Effective', value: false, id:4},
									{status:'cancelled', label:'Cancelled', value: true, id:3},
									{status:'closed', label:'Closed', value: false, id: 2},
								];
						} else if (offeringStatus === 'closed') {
							selectedMultiSelect = [
									{status:'active', label:'Active', value: false, id: 1},
									{status:'effective', label:'Effective', value: false, id:4},
									{status:'cancelled', label:'Cancelled', value: false, id:3},
									{status:'closed', label:'Closed', value: true, id: 2},
								];
						}
						let request = {
							status: offeringStatus,
							pagenum: 1
						}
						isOffering = true;
						props.getOfferings(request, true);
						return {
							activeCount: props.offeringMetaDataRes.data.offeringMetaData.data.active,
							closedCount: props.offeringMetaDataRes.data.offeringMetaData.data.closed,
							cancelledCount: props.offeringMetaDataRes.data.offeringMetaData.data.cancelled,
							effectiveCount: (props.offeringMetaDataRes.data.offeringMetaData.data.effective && props.offeringMetaDataRes.data.offeringMetaData.data.effective) ? props.offeringMetaDataRes.data.offeringMetaData.data.effective: 0,
							offeringMetaData: props.offeringMetaDataRes.data.offeringMetaData.data,
							multiSelect: selectedMultiSelect,
							searching: '',
							tableFilter: {
								field: null,
								order: null,
							},
							pagenum: 1,
							filterBy: 'name',
							offeringTableView: [],
							showOfferingTable: true,
							selectedOfferingStatus: offeringStatus
						};
					} else {
						return {
							activeCount: props.offeringMetaDataRes.data.offeringMetaData.data.active,
							closedCount: props.offeringMetaDataRes.data.offeringMetaData.data.closed,
							cancelledCount: props.offeringMetaDataRes.data.offeringMetaData.data.cancelled,
							effectiveCount: (props.offeringMetaDataRes.data.offeringMetaData.data.effective && props.offeringMetaDataRes.data.offeringMetaData.data.effective) ? props.offeringMetaDataRes.data.offeringMetaData.data.effective: 0,
							offeringMetaData: props.offeringMetaDataRes.data.offeringMetaData.data
						};
					}
					
					
				}
			}
		}
		return null;
	}
	offeringCustomHeader = ({tableManager, column, mode, ref, checked, disabled, indeterminate, onChange}) => {
        return <div>{column.label} {this.onSortFunction(column.field)}</div>
    }
	offeringDetailModal = (offeringInfo) => {
		this.setState({
			offeringInfo:offeringInfo,
			openDetailModal: true
		})
	}
	closeOfferingDetailModal = () => {
		this.setState({
			openDetailModal: false
		})
	}
	goToBounces = (data) => {
	  this.props.history.push({
		pathname: '/user-bounces',
		state: { offeringInfo: data}
	  })
	  
	}
	offeringsTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		if (value) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex'}}>
					<Tooltip title={value !== undefined ? value : ''} interactive={!value} >
						<div className="less-text" style={{width: '90%'}}>{value}</div>
					</Tooltip>
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button color="primary" style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
					</Tooltip>
				</div>
			)	
		}
	}

	callbacksTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner"> <Button variant="contained" color="primary" style={{ width: 40, height: 35 }} onClick={() => { this.goComm(data); }}><MailIcon/></Button> </div>
	}
	totalRequestedInvestors = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
	  return <div className="rgt-cell-inner"><Link className="green-color-with-pointer" to={{ pathname: `/offering-investors/${data.ext_id}`, state: { multiData: this.state.multiSelect, email: data.email, offeringStatus:  data.status, offeringData: data} }}>{data.total_requested_investors}</Link> </div>;
	}
	bouncesTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
	  let bounce = data.bounces;
	  return <div className="rgt-cell-inner " >
		{
		  bounce > 0 ? 
		  <div onClick={() => {this.goToBounces(data)}} style={{ color: 'red', cursor: 'pointer' }}>{bounce}</div>
		  :
		  <div>{data.bounces}</div>
		}
	  </div>;
	}
  
	minPriceTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
  
	  if(data.offering_type_name === "Follow-on"){
		 return <div className="rgt-cell-inner"> N/A </div>
	  }else{
		 return <div className="rgt-cell-inner"> {numberWithCommasAndDollar(data.min_price)} </div>
	  }
	}
  
	maxPriceTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
	  if(data.offering_type_name === "Follow-on"){
		 return <div className="rgt-cell-inner"> N/A </div>
	  }else{
		 return <div className="rgt-cell-inner"> {numberWithCommasAndDollar(data.max_price)} </div>
	  }
	}
  
	lastClosingPriceTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
	  if(data.offering_type_name === "Follow-on"){
		 return <div className="rgt-cell-inner"> {numberWithCommasAndDollar(data.last_closing_price)} </div>
	  }else{
		 return <div className="rgt-cell-inner"> N/A </div>
	  }
  
	}
  
	offeringNameTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
	  if(data.status==='cancelled'){
		 return <div className="rgt-cell-inner green-color-with-pointer update-error" onClick={()=>this.offeringDetailModal(data)}> {data.name}</div>
	  } else if(data.status==='closed'){
		   return <div className="rgt-cell-inner green-color-with-pointer closedofferingColor" onClick={()=>this.offeringDetailModal(data)}> {data.name}</div>
	  } else if(data.status==='effective'){
		   return <div className="rgt-cell-inner green-color-with-pointer effectiveofferingColor" onClick={()=>this.offeringDetailModal(data)}> {data.name}</div>
	  } else{
		 return <div className="rgt-cell-inner green-color-with-pointer" onClick={()=>this.offeringDetailModal(data)}> {data.name}</div>
	  }
	}

	amountTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
		return <div className="rgt-cell-inner"> {numberWithCommasAndDollar(data.total_requested_amount)} </div>
	}
  
	communicationTemplate = ({ tableManager, value, data, column, colIndex, rowIndex, onChange, disabled}) => {
	  return <div className="rgt-cell-inner"> <Button variant="contained" color="primary" style={{ width: 40, height: 35 }} onClick={() => { this.goComm(data); }}><MailIcon/></Button> </div>;
	}
	goComm = (rowData) => {
	  this.props.history.push({
		pathname: '/offering-callbacks',
		state: { offeringInfo: rowData}
	  })
	}
	optionClicked = (optionsList) => {
	  this.setState({ multiSelect: optionsList });
	}
	selectedBadgeClicked = (optionsList) => {
	  this.setState({ multiSelect: optionsList }, () => {
		// saveKey('offeringSelected', JSON.stringify(optionsList));
	  });
	}
	changeOfferingStatus = (e) => {
		this.setState({
			selectedOfferingStatus: e.target.value
		}, () => {
			if (this.state.selectedOfferingStatus === 'active') {
				this.setState({
					multiSelect:[
						{status:'active', label:'Active', value: true, id: 1},
						{status:'effective', label:'Effective', value: false, id:4},
						{status:'cancelled', label:'Cancelled', value: false, id:3},
						{status:'closed', label:'Closed', value: false, id: 2},
					],
					tableFilter: {
						field: null,
						order: null
					},
					searching: '',
					pagenum: 1,
					lastOfferingTableView: 0,
					offeringTableView: [],
				})
				let request = {
					status: this.state.selectedOfferingStatus,
					pagenum: 1
				}
				this.props.getOfferings(request, true);
				isOffering = true;
			} else if (this.state.selectedOfferingStatus === 'effective') {
				this.setState({
					multiSelect:[
						{status:'active', label:'Active', value: false, id: 1},
						{status:'effective', label:'Effective', value: true, id:4},
						{status:'cancelled', label:'Cancelled', value: false, id:3},
						{status:'closed', label:'Closed', value: false, id: 2},
					],
					tableFilter: {
						field: null,
						order: null
					},
					searching: '',
					pagenum: 1,
					lastOfferingTableView: 0,
					offeringTableView: [],
				})
				let request = {
					status: this.state.selectedOfferingStatus,
					pagenum: 1
				}
				this.props.getOfferings(request, true);
				isOffering = true;
			} else if (this.state.selectedOfferingStatus === 'cancelled') {
				this.setState({
					multiSelect:[
						{status:'active', label:'Active', value: false, id: 1},
						{status:'effective', label:'Effective', value: false, id:4},
						{status:'cancelled', label:'Cancelled', value: true, id:3},
						{status:'closed', label:'Closed', value: false, id: 2},
					],
					tableFilter: {
						field: null,
						order: null
					},
					searching: '',
					pagenum: 1,
					lastOfferingTableView: 0,
					offeringTableView: [],
				})
				let request = {
					status: this.state.selectedOfferingStatus,
					pagenum: 1
				}
				this.props.getOfferings(request, true);
				isOffering = true;
			} else if (this.state.selectedOfferingStatus === 'closed') {
				this.setState({
					multiSelect:[
						{status:'active', label:'Active', value: false, id: 1},
						{status:'effective', label:'Effective', value: false, id:4},
						{status:'cancelled', label:'Cancelled', value: false, id:3},
						{status:'closed', label:'Closed', value: true, id: 2},
					],
					tableFilter: {
						field: null,
						order: null
					},
					searching: '',
					pagenum: 1,
					lastOfferingTableView: 0,
					offeringTableView: [],
				})
				let request = {
					status: this.state.selectedOfferingStatus,
					pagenum: 1
				}
				this.props.getOfferings(request, true);
				isOffering = true;
			}
		})
	}
	export = () => {
		let csvContent = 'data:text/csv;charset=utf-8,';
			csvContent += 'Name, Offering type name, Ticker symbol, Anticipated Date, Effective Date, Final Price, Min Price, Max Price, Last Closing Price, Req Investors Count, User Bounces, Req Investment Amount, Allocated Shares, Allocated Investors Count, Allocated Investment Amount, Avg Allocated Order Size, Underwriter \r\n';
			this.state.offeringTableView.forEach(function (rowArray) {
				const newRow = rowArray.name + ',' + rowArray.offering_type_name + ',' + rowArray.ticker_symbol + ',' + rowArray.trade_date + ',' + rowArray.settlement_date + ',' + rowArray.final_price + ',' + rowArray.min_price + ',' + rowArray.max_price + ',' + rowArray.last_closing_price + ',' + rowArray.total_requested_investors + ',' + rowArray.bounces + ',' + rowArray.total_requested_amount + ',' + rowArray.total_allocated_investors + ',' + rowArray.total_allocated_amount + ',' + rowArray.average_order_size + ',' + rowArray.bd_underwriter_mpid_id + ',' + rowArray.total_requested_investors;
				csvContent += newRow + '\r\n'; // add carriage return
			});
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'offeringList.csv');
		document.body.appendChild(link); // Required for FF
	}
	onColumnToggle = (event) => {
		if (event.value.length > 0) {
		  	this.setState({cols: event.value});
		}
	}
	onSortFunction(field) {
		return (<span>
			{
				((this.state.tableFilter.field !== field)) &&
				<button className="sort-button sort-button-rotate" onClick={() => { this.doSort(field, 'asc'); }}><i className="fa fa-exchange" aria-hidden="true"></i></button>
			}
			{
				((this.state.tableFilter.order === 'asc') && (this.state.tableFilter.field === field)) &&
				<button className="sort-button" onClick={() => { this.doSort(field, 'desc'); }}><i className="fa fa-sort-amount-asc" aria-hidden="true"></i></button>
			}
			{
				((this.state.tableFilter.order === 'desc') && (this.state.tableFilter.field === field)) &&
				<button className="sort-button" onClick={() => { this.doSort(field, 'asc'); }}><i className="fa fa-sort-amount-desc" aria-hidden="true"></i></button>
			}
		</span>);
        
	}
	doSort(field, order) {
		if (this.state.offeringTableView) {
			if (order === null || order === 'asc') {
				this.setState({
					isSortActive: 'desc',
					tableFilter: {
						field,
						order,
					},
				}, () => { });
				let payload = {
					status: this.state.selectedOfferingStatus,
					pagenum: this.state.pagenum,
					sortby : field,
					sorttype: order,
				}
				if (this.state.searching !== '') {
					payload.search_key = this.state.filterBy;
					payload.search_keyword = this.state.searching;
				}
				this.props.getOfferings(payload, true);
				isOffering = true;
			} else {
				this.setState({
					isSortActive: 'asc',
					tableFilter: {
						field,
						order,
					},
				}, () => { });
				let payload = {
					status: this.state.selectedOfferingStatus,
					pagenum: this.state.pagenum,
					sortby : field,
					sorttype: order,
				}
				if (this.state.searching !== '') {
					payload.search_key = this.state.filterBy;
					payload.search_keyword = this.state.searching;
				}
				this.props.getOfferings(payload, true);
				isOffering = true;
			}
		}
        
	}
	handleClickSearch = () => {
		let payload = {
			status: this.state.selectedOfferingStatus,
			pagenum: 1,
		}
		if (this.state.tableFilter.field !== null) {
			payload.sortby = this.state.tableFilter.field;
			payload.sorttype = this.state.tableFilter.order;
		}
		
		if (this.state.searching !== '') {
			payload.search_keyword = this.state.searching.toLowerCase().trim();
			payload.search_key = this.state.filterBy;
		}
		this.setState({pagenum: 1})
		this.props.getOfferings(payload, true);
		isOffering = true;
	}
	fetchData = () => {
		if (this.state.lastOfferingTableView === this.state.offeringTableViewLength) {
			let payload = {
				status: this.state.selectedOfferingStatus,
				pagenum: this.state.pagenum + 1
			}
			if (this.state.tableFilter.field !== null) {
				payload.sortby = this.state.tableFilter.field;
				payload.sorttype = this.state.tableFilter.order;
			}
			
			if (this.state.searching !== '') {
				payload.search_keyword = this.state.searching;
				payload.search_key = this.state.filterBy;
			}
			this.props.getOfferings(payload, false);
			isOffering = true;
			this.setState({
				pagenum: this.state.pagenum + 1,
				isFetchOffering: true
			})
		}
	}
	searchOffering = (event) => {
		this.setState({
			searching: event.target.value,
		});
	}
	doRefreshOffering = () => {
		let request = {
			status: this.state.selectedOfferingStatus,
			pagenum: 1,
        }
        if (this.state.tableFilter.field !== null) {
            request.sortby = this.state.tableFilter.field;
            request.sorttype = this.state.tableFilter.order;
		}
		this.setState({pagenum: 1, isFetchOffering: true})
		isOffering = true;
		
        this.props.getOfferings(request, true);
	}
	handleBlur = () => {
		if (this.state.searching === '') {
			this.doRefreshOffering();
		}
	}
	handleContainerOnBottom = () => {
        this.fetchData()
	};
	loadMore = () => {
		this.fetchData();
    }
	showOfferingTable = (status) => {
		if (status === 'active') {
			this.setState({
				multiSelect:[
					{status:'active', label:'Active', value: true, id: 1},
					{status:'effective', label:'Effective', value: false, id:4},
					{status:'cancelled', label:'Cancelled', value: false, id:3},
					{status:'closed', label:'Closed', value: false, id: 2},
				]
			})
		} else if (status === 'effective') {
			this.setState({
				multiSelect:[
					{status:'active', label:'Active', value: false, id: 1},
					{status:'effective', label:'Effective', value: true, id:4},
					{status:'cancelled', label:'Cancelled', value: false, id:3},
					{status:'closed', label:'Closed', value: false, id: 2},
				]
			})
		} else if (status === 'cancelled') {
			this.setState({
				multiSelect:[
					{status:'active', label:'Active', value: false, id: 1},
					{status:'effective', label:'Effective', value: false, id:4},
					{status:'cancelled', label:'Cancelled', value: true, id:3},
					{status:'closed', label:'Closed', value: false, id: 2},
				]
			})
		} else if (status === 'closed') {
			this.setState({
				multiSelect:[
					{status:'active', label:'Active', value: false, id: 1},
					{status:'effective', label:'Effective', value: false, id:4},
					{status:'cancelled', label:'Cancelled', value: false, id:3},
					{status:'closed', label:'Closed', value: true, id: 2},
				],
			})
		}
		this.setState({
			searching: '',
			tableFilter: {
                field: null,
                order: null,
			},
			pagenum: 1,
			filterBy: 'name',
			offeringTableView: [],
			showOfferingTable: true,
			selectedOfferingStatus: status
		});
		let request = {
			status: status,
			pagenum: 1
		}
		localStorage.setItem('setStatus', status);
		isOffering = true;
		this.props.getOfferings(request, true);
	}
	render() {
		const { offeringTableView, offeringInfo } = this.state;
		let headers = <div className="row mb-4 mt-4">
			<div className="col-md-8">
				<h3 className="col-md-12" style={{textTransform: 'capitalize'}}>{this.state.selectedOfferingStatus} offerings</h3>
				{/* <FormControl style={{width: 150}}>
					<Select
					labelId="Offering-label"
					id="offering"
					value={this.state.selectedOfferingStatus}
					onChange={this.changeOfferingStatus}
					>
						{
							this.state.multiSelect && this.state.multiSelect.map((multi, i) => 
								<MenuItem key={i} value={multi.status}>{multi.label}</MenuItem>
							)
						}
					</Select>
				</FormControl> */}
			</div>
			{/* <div className="fieldsDesign select-date-format">
				<Calendar placeholder="Date From" value={this.state.fromdate} onChange={(e) => this.setState({ fromdate: e.value })} view="month" dateFormat="mm/yy" yearNavigator={true} yearRange="2010:2030"></Calendar>
			</div>
			<div className="col-md-2">
				<Calendar placeholder="Date To" value={this.state.todate} onChange={(e) => {
					let last = e.value;
					let lastDay = new Date(last.getFullYear(),
						last.getMonth(), this.daysInMonth(last.getMonth() + 1,
							last.getFullYear()));
					this.setState({ todate: lastDay })
				}} view="month" dateFormat="mm/yy" yearNavigator={true} yearRange="2010:2030"></Calendar>
			</div> */}
			<div className="col-md-2">
				<TextField 
					id="searchInput" 
					type="text" 
					placeholder="Search" 
					value={this.state.searching} 
					onChange={this.searchOffering}
					onBlur={this.handleBlur}
					InputProps={{
						endAdornment: (
								<InputAdornment position="start">
								<Button variant="contained" color="primary" style={{borderRadius: '0px 4px 4px 0px', minWidth: 50, top: 19, right: 0, left: 8, height: 38}} onClick={() => this.handleClickSearch()} disabled={this.state.searching !== '' ? false : true}><SearchIcon /></Button>
								</InputAdornment>
						)
					}}
				/>
			</div>
			<div className="col-md-2">
				<Button variant="contained" color="primary" fullWidth={true} className="btn" onClick={this.export}>Download CSV</Button>
			</div>
		</div>
		return (
			<Dashboard title="ClickIPO Brokerdealer - Offerings" pageTitle="Offerings" >
				<div className="offering_content_main">
					<div>
						{/* {
							<DataTable value={filterByStatus} globalFilter={this.state.searching} header={headers} responsive={true} paginator={true} rows={50} sortMode="multiple"  scrollable={true} ref={(el) => { this.dt = el; }} resizableColumns={true} columnResizeMode="expand" style={{width: '100%'}}  className="offeringTableResponsive" emptyMessage="No records found">
								{columns}
							</DataTable>
						} */}
						<div className="row">
							<div className="col-md-3">
								<Tooltip title="Click to see more detail" placement="bottom">
									<Paper elevation={3} className="offering-stats-box" onClick={() => this.showOfferingTable('active')}>
										<div className="row">
											<div className="col-md-6">
												<div className="offering-stats-box-icon-active">
													<CheckIcon style={{color: '#fff', fontSize: 30}} />
												</div>
											</div>
											<div className="col-md-6">
												<h2 className="mt-2" style={{textAlign: 'right'}}>{this.state.activeCount}</h2>
											</div>
										</div>
										<p className="mt-4" style={{textAlign: 'center'}}>Active Offerings</p>
									</Paper>
								</Tooltip>
							</div>
							<div className="col-md-3">
								<Tooltip title="Click to see more detail" placement="bottom">
									<Paper elevation={3} className="offering-stats-box" onClick={() => this.showOfferingTable('effective')}>
										<div className="row">
											<div className="col-md-6">
												<div className="offering-stats-box-icon-effective">
													<TrendingUpIcon style={{color: '#fff', fontSize: 30}} />
												</div>
											</div>
											<div className="col-md-6">
												<h2 className="mt-2" style={{textAlign: 'right'}}>{this.state.effectiveCount}</h2>
											</div>
										</div>
										<p className="mt-4" style={{textAlign: 'center'}}>Effective Offerings</p>
									</Paper>
								</Tooltip>
							</div>
							<div className="col-md-3">
								<Tooltip title="Click to see more detail" placement="bottom">
									<Paper elevation={3} className="offering-stats-box" onClick={() => this.showOfferingTable('cancelled')}>
										<div className="row">
											<div className="col-md-6">
												<div className="offering-stats-box-icon-cancelled">
													<BlockIcon style={{color: '#fff', fontSize: 30}} />
												</div>
											</div>
											<div className="col-md-6">
												<h2 className="mt-2" style={{textAlign: 'right'}}>{this.state.cancelledCount}</h2>
											</div>
										</div>
										<p className="mt-4" style={{textAlign: 'center'}}>Cancelled Offerings</p>
									</Paper>
								</Tooltip>
							</div>
							<div className="col-md-3">
								<Tooltip title="Click to see more detail" placement="bottom">
									<Paper elevation={3} className="offering-stats-box" onClick={() => this.showOfferingTable('closed')}>
										<div className="row">
											<div className="col-md-6">
												<div className="offering-stats-box-icon-closed">
													<CancelIcon style={{color: '#fff', fontSize: 30}} />
												</div>
											</div>
											<div className="col-md-6">
												<h2 className="mt-2" style={{textAlign: 'right'}}>{this.state.closedCount}</h2>
											</div>
										</div>
										<p className="mt-4" style={{textAlign: 'center'}}>Closed Offerings</p>
									</Paper>
								</Tooltip>
							</div>
						</div>
						{
							(this.state.showOfferingTable) &&
							<div>
								{headers}
								<div className="table-responsive custom-offering-table" id="mainTable">
									<GridTable
										columns={this.state.cols}
										rows={offeringTableView}
										isPaginated={false}
										isVirtualScroll={true}
										showSearch={false}
										showRowsInformation={false}
										isHeaderSticky={true}
									/>
									<div className="col-md-12 mt-2">
										{
											(this.state.lastOfferingTableView === this.state.offeringTableViewLength) ?
												<Button variant="contained" color="primary" onClick={() => this.loadMore()} style={{ marginRight: 8, marginBottom: 8 }} disabled={this.state.pagenum === -1}>Load more</Button>
												:
												<Button variant="contained" color="primary" style={{ marginRight: 8, marginBottom: 8 }} disabled={true}>Load more</Button>
										}

									</div>
								</div>
							</div>
						}
						<Dialog
							onClose={this.closeOfferingDetailModal}
							aria-labelledby="customized-dialog-title"
							open={this.state.openDetailModal}
							maxWidth={"sm"}
							fullWidth={true}
							scroll={'paper'}
							>
							<DialogTitle onClose={this.closeOfferingDetailModal} className="offering-detail-title">{this.state.offeringInfo.name}</DialogTitle>
							<DialogContent>
								<div className="col-md-12">
									<div className="row">
										<div className="col-sm-12">
											<div className="row">
												<div className="col-sm-5 offeringDetailTitle">Name</div>
												<div className="col-sm-7 offeringDetailSubTitle">{offeringInfo.name} ({offeringInfo.ticker_symbol})</div>
											</div>
											<div className="row">
												<div className="col-sm-5 offeringDetailTitle">Status</div>
												<div className="col-sm-7 offeringDetailSubTitle">{offeringInfo.status}</div>
											</div>
											<div className="row">
												<div className="col-sm-5 offeringDetailTitle">Type</div>
												<div className="col-sm-7 offeringDetailSubTitle">{offeringInfo.offering_type_name}</div>
											</div>
											<div className="row">
												<div className="col-sm-5 offeringDetailTitle">Anticipated Date</div>
												<div className="col-sm-7 offeringDetailSubTitle">{(offeringInfo.trade_date)}</div>
											</div>
											<div className="row">
												<div className="col-sm-5 offeringDetailTitle">Effective Date</div>
												<div className="col-sm-7 offeringDetailSubTitle">{(offeringInfo.settlement_date)}</div>
											</div>
											{(offeringInfo.status === "closed" || offeringInfo.status === "cancelled") &&
											<div className="row">
												<div className="col-sm-5 offeringDetailTitle">Final Price</div>
												<div className="col-sm-7 offeringDetailSubTitle">$ {offeringInfo.final_price}</div>
											</div>
											} {offeringInfo.status === "active" &&
											<div className="row">
												<div className="col-sm-5 offeringDetailTitle">Price Range</div>
												<div className="col-sm-7 offeringDetailSubTitle">
													$ {offeringInfo.min_price} - $ {offeringInfo.max_price}
												</div>
											</div>
											}
											<div className="row">
												<div className="col-sm-5 offeringDetailTitle">Prospectus Url</div>
												<div className="col-sm-7 offeringDetailSubTitle">{offeringInfo.prospectus_url}</div>
											</div>
											<div className="row">
												<div className="col-sm-5 offeringDetailTitle">Description</div>
												<div className="col-sm-7 offeringDetailSubTitle">{offeringInfo.description}</div>
											</div>
										</div>
									</div>

								</div>
							</DialogContent>
						</Dialog>
					</div>
				</div>
			</Dashboard>
		)
	}
}

OfferingComponent.propTypes = {
	offeringRes: PropTypes.any,
	offeringMetaDataRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	offeringRes: doOfferingRes,
	offeringMetaDataRes: doOfferingMetaDataRes,
});

function mapDispatchToProps(dispatch) {
	return {
		getOfferings: (data, isLoading) => dispatch(getOffering(data, isLoading)),
		getOfferingMetaData: () => dispatch(getOfferingMetaData()),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingComponent);
