import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Ionicon from 'react-ionicons';
import styles from './sidebar-jss';
import classNames from 'classnames';
import ListIcon from '@material-ui/icons/List';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ContactsIcon from '@material-ui/icons/Contacts';
// import PolicyRoundedIcon from '@material-ui/icons/PolicyRounded';
// import GavelRoundedIcon from '@material-ui/icons/GavelRounded';
import { logout } from '../../helper/helper';
const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} style={{color: '#333'}} />; // eslint-disable-line
});

let page="";
// eslint-disable-next-line
class MainMenu extends React.Component {
  // handleClick() {
  //   const { toggleDrawerOpen, loadTransition } = this.props;
  //   toggleDrawerOpen();
  //   loadTransition(false);
  // }
  openurl(geturl) {
    const url = geturl;
    window.open(url, '_blank');
  }
  componentDidMount() {
    page =window.location.pathname;
    page =page.split('/');
  }
  render() {
    const {
      classes
    } = this.props;
    return (
      <div>
        {/* {getMenus(dataMenu)} */}
        <ListItem
          button
          className={classNames(classes.head, page[1] === 'offerings' || page[1] === 'offering-callbacks' || page[1] === 'offering-communication' ? ` ${classes.active}` : '')}
          component={LinkBtn}
          to={{pathname: "/offerings",
          DSPView: true}}
        >
          <ListItemIcon className={classes.icon}>
            <ListIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} invariant="inset" primary={'Offerings'} />
        </ListItem>

        <ListItem
          button
          exact
          className={classNames(classes.head, page[1] === 'investors' || page[1] === 'investor-communication' || page[1] === 'investor-detail' ? ` ${classes.active}` : '')}
          component={LinkBtn}
          to={'/investors'}
        >
          <ListItemIcon className={classes.icon}>
            <SupervisedUserCircleIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Investors'} />
        </ListItem>

        <ListItem
          button
          exact
          className={classes.head}
          activeClassName={classes.active}
          component={LinkBtn}
          to={'/connected-customers'}
        >
          <ListItemIcon className={classes.icon}>
            <RecentActorsIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Connected Customers'} />
        </ListItem>

        <ListItem
          button
          exact
          className={classes.head}
          activeClassName={classes.active}
          component={LinkBtn}
          to={'/profile'}
        >
          <ListItemIcon className={classes.icon}>
            <AccountCircleRoundedIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Profile'} />
        </ListItem>

        <ListItem
          button
          exact
          className={classes.head}
          activeClassName={classes.active}
          component={LinkBtn}
          to={'/accounts'}
        >
          <ListItemIcon className={classes.icon}>
            <ContactsIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Account'} />
        </ListItem>

        <ListItem
          button
          className={classes.head}
          onClick={logout}
        >
          <ListItemIcon className={classes.icon}>
            <ExitToAppRoundedIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Sign Out'} />
        </ListItem>
      </div>
    );
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired
};

// const openAction = (key, keyParent) => ({ type: 'OPEN_SUBMENU', key, keyParent });

const mapStateToProps = state => ({
  force: state, // force active class for sidebar menu
});

const mapDispatchToProps = dispatch => ({
  // openSubMenu: bindActionCreators(openAction, dispatch)
});

const MainMenuMapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu);

export default withStyles(styles)(MainMenuMapped);
