import { getItem } from '../utils/localStore';
import { BASE_URL } from '../constants';
import Swal from 'sweetalert2';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class deleteOrderApi {  
    static doDeleteOrder(request) {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'DELETE',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(request)
            }
            return fetch(BASE_URL + '/offerings/delete_orders', body).then(response => {
                if (response.ok === true) {
                    Swal.fire({
						title: 'Cancel Order Successfully',
						icon: 'success',
						confirmButtonText: 'OK',
						allowOutsideClick: false,
						timer: 3000,
                        heightAuto: false
					});
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}  

export default deleteOrderApi;