import * as types from './actionTypes';
import forgotPasswordApi from '../api/forgotPasswordApi';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doForgotpassword(data) {
  return {
    type: types.DO_FORGOTPASSWORD,
    data
  };
}

export function doForgotpasswordRes(data) {
  return {
    type: types.DO_FORGOTPASSWORD_RES,
    data
  };
}
export function submitForgotpassword(data) {
  return function(dispatch) {
    dispatch(loadingRes(true));
    forgotPasswordApi.doForgotpassword(data).then(data => {
      dispatch(doForgotpasswordRes(data));
      dispatch(loadingRes(false));
      if(data.error){
        Swal.fire({
          title: data.message,
          icon: 'error',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          timer: 3000,
          heightAuto: false
        })
      }
    }).catch(error => {
      dispatch(loadingRes(false));
      return error
    });
  };
}