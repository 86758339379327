import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { doResetpasswordRes } from '../../../actions/resetPasswordActions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import logo from '../../../assets/images/clickipo_logo.png';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import ArrowForward from '@material-ui/icons/ArrowForward';
import TextField from '@material-ui/core/TextField';
import styles from '../../login/login-jss';

const fields = ['password', 'password_confirmation'];

const required = value => (value == null ? 'Required' : undefined);

export const TextFieldRedux = ({ meta: { touched, error }, input, ...rest }) => {
    return (
        <div>
            <TextField
                style={{width: '100%'}}
                {...rest}
                {...input}
                value={input.value}
                error={touched && Boolean(error)}
                
            />
            <div>{touched && error && <span style={{color: 'red'}}>{error}</span>}</div>
        </div>
    );
};


const validate = values => {
    const errors = {}

    if (!values.password) {
        errors.password = 'Required'
    }
    if (!values.password_confirmation) {
        errors.password_confirmation = 'Required'
    }
    if (values.password) {
        if (values.password.search(/^\S+$/)) {
            errors.password = 'Your password must not contain any space.'
        }
        if (values.password.length < 10) {
            errors.password = 'Your password must be at least 10 characters'
        }
    }
    if (values.password_confirmation) {
        if (values.password) {
            if (values.password_confirmation !== values.password) {
                errors.password_confirmation = "Passwords don't match"
            }
        }
        
    }
    return errors
}

class ResetPasswordForm extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            showPassword: false,
        }
    }

    handleClickShowPassword = () => {
        const {
            showPassword
        } = this.state;
        this.setState({
            showPassword: !showPassword
        });
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };
    render() {
        const { handleSubmit, handleFormSubmit, pristine, submitting, classes } = this.props
        return (
            <Fragment>
                <Hidden mdUp>
                    <NavLink to="/" className={classNames(classes.brand, classes.outer)}>
                        <img src={logo} alt={'logo'} />
                    </NavLink>
                </Hidden>
                <Paper className={classNames(classes.paperWrap, classes.petal)}>
                    <Hidden smDown>
                        <div className={classes.topBar}>
                            <NavLink to="/" className={classes.brand}>
                                <img src={logo} alt={'logo'} />
                            </NavLink>
                        </div>
                    </Hidden>
                    <Typography variant="h4" className={classes.title} gutterBottom>
                        Change Password
                    </Typography>
                    <section className={classes.formWrap}>
                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <div>
                                <FormControl className={classes.formControl}>
                                    <Field
                                        name="password"
                                        component={TextFieldRedux}
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        label="New Password"
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        )
                                        }}
                                        required
                                        validate={required}
                                        className={classes.field}
                                    />
                                    <Field
                                        name="password_confirmation"
                                        component={TextFieldRedux}
                                        type={'password'}
                                        label="Confirm New Password"
                                        required
                                        validate={required}
                                        className={classes.field}
                                    />
                                </FormControl>
                            </div>
                            <div className={classes.btnArea}>
                                <Button variant="contained" color="primary" type="submit">
                                    Submit
                                    <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} disabled={submitting || pristine} />
                                </Button>
                            </div>
                        </form>
                    </section>
                </Paper>
            </Fragment>
        )
    }
}

ResetPasswordForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleFormSubmit: PropTypes.func,
    doResetpasswordRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    doResetpasswordRes: doResetpasswordRes
});
  
function mapDispatchToProps(dispatch,ownProps) {
    return {
        
    };
}

const ReduxResetPasswordForm = reduxForm({
    form: 'ReduxResetPasswordForm',
    validate,
    fields,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: false,
})(ResetPasswordForm)

const resetPassForm = connect(mapStateToProps, mapDispatchToProps)(ReduxResetPasswordForm);
export default withStyles(styles)(resetPassForm);