import * as types from './actionTypes';  
import offeringApi from '../api/offeringApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doOffering(request, isLoading) {

  return {
    type: types.DO_OFFERING, 
    request, isLoading
  };
}

export function doOfferingRes(data) {  
  return {
    type: types.DO_OFFERING_RES, 
    data
  };
}

export function getOffering(request, isLoading) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      if (isLoading) {
        dispatch(loadingRes(true));
      }
      offeringApi.doOffering(request).then(data => {
        dispatch(doOfferingRes(data));
        dispatch(doOfferingRes(null));
        if (isLoading) {
          dispatch(loadingRes(false));
        }
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        if (isLoading) {
          dispatch(loadingRes(false));
        }
        return error
      });
    };
  }else{
    logout()
  }
}

export function doOfferingMetaDataRes(data) {  
  return {
    type: types.DO_OFFERING_METADATA_RES, 
    data
  };
}

export function getOfferingMetaData() {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      offeringApi.doOfferingMetaData().then(data => {
        dispatch(doOfferingMetaDataRes(data));
        dispatch(doOfferingMetaDataRes(null));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}
