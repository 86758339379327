
import { BASE_URL } from '../constants';
import { sha3_512 } from 'js-sha3';
import Swal from 'sweetalert2';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class resetPasswordApi {
    static doResetpassword(data) {

        let sendBody={
            reset_password_token: data.reset_password_token,
            encrypted_password:sha3_512(data.password),
        }
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json'
            });
            let body = {
                method: 'PUT',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(sendBody)
            }
            return fetch(BASE_URL + '/auth/password', body).then(response => {
                if(response.ok === true){
                    Swal.fire({
                        title: 'Password Reset Successful',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: true,
                        timer: 3000,
                        heightAuto: false
                    })
                    setTimeout(function() { 
                        window.location.href = '/';
                    }, 3000);
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default resetPasswordApi;
