import * as types from './actionTypes';  
import loginApi from '../api/loginApi';
import Swal from 'sweetalert2';
import { setItem } from '../utils/localStore';
import { loadingRes } from './loadingActions';

export function doLogin(data) {

  return {
    type: types.DO_LOGIN, 
    data
  };
}

export function doLoginRes(user) {  
  return {
    type: types.DO_LOGIN_RES, 
    user
  };
}

export function submit_login(data) {
  return function(dispatch) {
    dispatch(loadingRes(true));
    loginApi.doLogin(data).then(user => {
      if (user.data) {
          setItem('last_password_changed', user.data.last_password_changed);
          setItem('level', user.data.level);
          if (user.data.level === 10 || user.data.level === 11) {
            dispatch(doLoginRes(user));
            dispatch(doLoginRes(null));
          } else {
            Swal.fire({
              title: "Invalid credentials",
              icon: 'error',
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              timer: 3000,
              heightAuto: false
            })
          }
      }
      dispatch(loadingRes(false));
      if(user.error){
        Swal.fire({
          title: user.message,
          icon: 'error',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          timer: 3000,
          heightAuto: false
        })
      }
    }).catch(error => {
      dispatch(loadingRes(false));
      return error
    });
  };
}