import { combineReducers } from 'redux';
import userReducer from './userReducer';
import loadingReducer from './loadingReducer';
import offeringReducer from './offeringReducer';
import { reducer as formReducer } from 'redux-form';
import offeringDetailReducer from './offeringDetailReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import changePasswordReducer from './changePasswordReducer';
import resetPasswordReducer from './resetPasswordReducer';
import getprofileReducer from './getprofileReducer';
import offeringCallBacksReducer from './offeringCallBacksReducer';
import connectedCustomersReducer from './connectedCustomersReducer';
import investorsReducer from './investorsReducer';
import accountReducer from './accountReducer';
import accountCreateReducer from './accountCreateReducer';
import customerListReducer from './customerListReducer';
import buyingPowerReducer from './buyingPowerReducer';
import deleteOrderReducer from './deleteOrderReducer';
import updateRequestedAmountReducer from './updateRequestedAmountReducer';
import userDetailReducer from './userDetailReducer';
import activeOfferingReducer from './activeOfferingReducer';
import offeringCommunicationReducer from './offeringCommunicationReducer';
import userBouncesReducer from './userBouncesReducer';
import userBounceDetailReducer from './userBounceDetailReducer';
import resendUserBounceReducer from './resendUserBounceReducer';
import userBouncePrintReducer from './userBouncePrintReducer';
import ordersMetaDataReducer from './ordersMetaDataReducer';
import customerResetPassReducer from './customerResetPassReducer';
import offeringMetaDataReducer from './offeringMetaDataReducer';

export default combineReducers({
  form: formReducer,
  user: userReducer,
  isLoading: loadingReducer,
  offering: offeringReducer,
  offeringdetail: offeringDetailReducer,
  getprofile: getprofileReducer,
  forgotPassword: forgotPasswordReducer,
  changePassword: changePasswordReducer,
  resetPassword: resetPasswordReducer,
  offeringCallBacks: offeringCallBacksReducer,
  connectedCustomers: connectedCustomersReducer,
  investorsList: investorsReducer,
  accountList: accountReducer,
  accountCreate: accountCreateReducer,
  customerList: customerListReducer,
  buyingPower: buyingPowerReducer,
  deleteOrder: deleteOrderReducer,
  updateRequestedAmount: updateRequestedAmountReducer,
  userDetail: userDetailReducer,
  activeOffering: activeOfferingReducer,
  offeringCommunication: offeringCommunicationReducer,
  userBounces: userBouncesReducer,
  userBounceDetail: userBounceDetailReducer,
  resendUserBounce: resendUserBounceReducer,
  userBouncePrint: userBouncePrintReducer,
  ordersMetaData: ordersMetaDataReducer,
  customerResetPass: customerResetPassReducer,
  offeringMetaData: offeringMetaDataReducer,
});
