import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './profile.scss';
import Dashboard from '../../component/Dashboard';
import { getProfile, doProfileRes } from '../../actions/profileActions';
import TextField from '@material-ui/core/TextField';

class ProfileComponent extends React.PureComponent {

	constructor(props){
		super(props);
		this.state = {
			profileData: [],
			first_name: '',
			last_name: '',
			email: '',
		}
	}
	componentDidMount() {
		this.props.getProfile();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.profileRes) {
			if (props.profileRes.data && props.profileRes.data.getprofile) {
				if (props.profileRes.data.getprofile.error === 0) {
					return {
						profileData: props.profileRes.data.getprofile.data,
						first_name: props.profileRes.data.getprofile.data.first_name,
						last_name: props.profileRes.data.getprofile.data.last_name,
						email: props.profileRes.data.getprofile.data.email
					};
				}
			}
		}
		return null;
	}
	render() {

		return (
			<Dashboard title="ClickIPO Offerings"  pageTitle="Profile">
				<div className="profile_content">
					<div className="row justify-content-md-center">
						<div className="col-md-4">
							<div className="profile-title">Profile</div>
						</div>
					</div>
					<div style={{ marginTop: 30 }}>
						{
							<div className="col-md-12">
								<div className="row justify-content-md-center">
									<div className="col-md-4">
										<div>
											<TextField
												label="First name"
												type="text"
												name="first_name"
												margin="normal"
												variant="outlined"
												value={(this.state.first_name) ? this.state.first_name : ''}
												style={{width: '100%'}}
												disabled={true}
											/>
										</div>
										<div>
											<TextField
												label="Last name"
												type="text"
												name="last_name"
												margin="normal"
												variant="outlined"
												value={(this.state.last_name) ? this.state.last_name : ''}
												style={{width: '100%'}}
												disabled={true}
											/>
										</div>
										<div>
											<TextField
												label="Email"
												type="text"
												name="email"
												margin="normal"
												variant="outlined"
												value={(this.state.email) ? this.state.email : ''}
												style={{width: '100%'}}
												disabled={true}
											/>
										</div>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</Dashboard>
		)
	}
}

ProfileComponent.propTypes = {
	callBacksRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	profileRes: doProfileRes,
});

function mapDispatchToProps(dispatch) {
	return {
		getProfile: () => dispatch(getProfile())
		
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ProfileComponent);