import * as types from '../actions/actionTypes'; 
import initialState from './initialState';

export default function (state = initialState.user, action) {
  switch(action.type) {
    case types.DO_CREATE_ACCOUNTS_RES:
      return action.data
    default:
      return state
  }
}
