import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './userBounceList.scss';
import Dashboard from '../../component/Dashboard';
import { getUserBounces, doUserBouncesRes, getUserBounceDetail, doUserBounceDetailRes, resendUserBounces, doResendUserBouncesRes, printUserBounces, doPrintUserBouncesRes } from '../../actions/userBouncesActions';
import { getProfile, doProfileRes } from '../../actions/profileActions';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import GridTable from '@nadavshaar/react-grid-table';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DOMPurify from 'dompurify';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

let userProspectus = '';
let emailBody = '';
let isPrint = false;
let isResend = false;
let isBounceDetail = false;
let isProfile = false
class UserBounceListComponent extends React.PureComponent {

	constructor(props){
		super(props);
        isPrint = false;
        isResend = false;
		isBounceDetail = false;
		isProfile = false;
		const columns = [
			{ id: 1, width: '20%', field: 'email', label: 'Email', visible: true, sortable: true, cellRenderer: this.bounceMessageTemp },
			{ id: 2, width: '20%', field: 'subject', label: 'Subject', visible: true, sortable: true, cellRenderer: this.subjectTemplate },
			{ id: 3, width: '20%', field: 'created_at', label: 'Sent At', visible: true, sortable: true , cellRenderer: this.bounceMessageTemp},
			{ id: 4, width: '20%', field: 'bounced_at', label: 'Bounced At', visible: true, sortable: true, cellRenderer: this.bounceMessageTemp },
			{ id: 5, width: '20%', field: 'bounce_message', label: 'Bounce Message', visible: true, sortable: true, cellRenderer: this.bounceMessageTemp },
		];
		this.state = {
			cols: columns,
			offeringDetail: null,
            showProspectusView: false,
            userBouncesData: null,
            showPrintmodal: false,
            profileData: null,
            isPrintDisable: false,
            userBouncesListData: [],
            bounceDetails: null,
			lastIndex: null,
		}
	}
	componentDidMount() {
		let offering = this.props.location.state.offeringInfo;
        this.setState({
            offeringDetail: offering,
        }, () => {
		});
		
		this.props.getUserBounces(offering.ext_id);
		isProfile = true;
        this.props.getProfile();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.userBouncesRes) {
			if (props.userBouncesRes.data && props.userBouncesRes.data.userBounces) {
				if (props.userBouncesRes.data.userBounces.message === 'Success') {
					let arrData = props.userBouncesRes.data.userBounces.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };
					return {
						userBouncesListData: arrData
					};
				}
			}
		}
		if (props.profileRes) {
			if (props.profileRes.data && props.profileRes.data.getprofile) {
				if (props.profileRes.data.getprofile.message === 'Success' && isProfile) {
					isProfile = false
					return {
						profileData: props.profileRes.data.getprofile.data
					};
				}
			}
		}
		
		if (props.userBounceDetailRes) {
			if (props.userBounceDetailRes.data && props.userBounceDetailRes.data.userBounceDetail) {
				if (props.userBounceDetailRes.data.userBounceDetail.message === 'Success' && isBounceDetail) {
					isBounceDetail = false;
					return {
						bounceDetails: props.userBounceDetailRes.data.userBounceDetail.data
					};
				}
			}
		}
		return null;
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.resendUserBouncesRes) {
			if (prevProps.resendUserBouncesRes.data && prevProps.resendUserBouncesRes.data.resendUserBounce) {
				if (prevProps.resendUserBouncesRes.data.resendUserBounce.message === 'Success' && isResend) {
					isResend = false;
					this.setState({
						isResendDisable: false,
					}, () => { 
						this.afterPrintDo();
					});
				}
			} else {
				setTimeout(()=>{
					this.setState({
						isResendDisable: false,
					});
				},2000);
			}
		}
		if (prevProps.printUserBouncesRes) {
			if (prevProps.printUserBouncesRes.data && prevProps.printUserBouncesRes.data.userBouncePrint) {
				if (prevProps.printUserBouncesRes.data.userBouncePrint.message === 'Success' && isPrint) {
					isPrint = false;
					this.setState({
						showPrintmodal: false,
						isPrintDisable: false,
					}, () => {
						setTimeout(()=>{
							this.showPrintView();
							window.addEventListener("afterprint", this.afterPrintDo());
						},2000)
					});
				}  else {
					setTimeout(()=>{
						this.setState({
							isPrintDisable: false,
						});
					},2000);
				}
			}
		}
	}
	subjectTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (value) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex'}}>
					<Tooltip title={value !== undefined ? value : ''} interactive={!value} >
						<div className="less-text" style={{width: '90%', cursor: 'pointer', color: '#8DC73F'}} onClick={() => this.showProspectus(data, rowIndex - 1)}>{value}</div>
					</Tooltip>
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button color="primary" style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
					</Tooltip>
				</div>
			)	
		}
    }
    afterPrintDo = () => {
        
        if (this.state.userBouncesListData.length === 1) {
            this.setState({
                showProspectusView: false,
                userBouncesData: null
            });
            this.goBackNavigate();
        } else {
            this.setState({
                showProspectusView: false,
                userBouncesData: null
            });
            let localArr = this.state.userBouncesListData;
            localArr.splice(this.state.lastIndex, 1);
            this.setState({
                userBouncesListData: localArr
            });
        } 
    }
    showProspectus = (data, index) => {
		this.props.doUserBounceDetailRes(null);
        this.setState({
            showProspectusView: true,
            userBouncesData: data,
            lastIndex: index,
			bounceDetails: null,
        });
        setTimeout(() => { this.scrollToBottom('end'); }, 300);
        isBounceDetail = true;
        this.props.getUserBouncesDetails(data.s3_file_url);
    }
    handleResendProspect = () => {
        let request = {
            s3_file_url: this.state.userBouncesData.s3_file_url
        }
		isResend = true;
        this.props.resendUserBounces(request);
    }
    handleResendPrint = () => {
        let request = {
            s3_file_url: this.state.userBouncesData.s3_file_url,
            confirm: "I " + this.state.profileData.first_name + " " + this.state.profileData.last_name + " take responsibility of printing and mailing the prospectus to the user. ClickIPO can mark this message as delivered."
        }
        isPrint = true;
        this.setState({
            isPrintDisable: true,
        });
        this.props.printUserBounces(request);
    }
    openPrientModal = () => {
        this.setState({
            showPrintmodal: true,
        });
    }
    closePrientModal = () => {
        this.setState({
            showPrintmodal: false,
        });
    }
    scrollToBottom = (scope) => {
        this.refs.prospect.scrollIntoView({
            behavior: 'smooth',
            block: scope,
        });
    }
    goBackNavigate = () => {
        this.props.history.goBack();
    }
    showPrintView = () => {
        var mywindow = window.open();
        mywindow.document.write(document.getElementById('printdiv').innerHTML);
       
        mywindow.print();
        mywindow.close();

        return true;
	}
	executeOnClick = (isExpanded) => {

	}
	bounceMessageTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (value) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex'}}>
					<Tooltip title={value !== undefined ? value : ''} interactive={!value} >
						<div className="less-text" style={{width: '90%'}}>{value}</div>
					</Tooltip>
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button color="primary" style={{padding: 8, minWidth: 20}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
					</Tooltip>
				</div>
			)	
		}
	}
	render() {
		const { offeringDetail, showProspectusView, userBouncesListData, bounceDetails } = this.state;
		if (bounceDetails) {
			emailBody = bounceDetails.email_body;
			userProspectus = bounceDetails.prospectus;
		}
		let headers =   <div className="row">
			<div className="col-md-4">
				<div className="col-sm-12">
				{(offeringDetail && offeringDetail.name) && 
					<div className="callback-back">
						<div  onClick={() => { this.goBackNavigate(); }}>
							<ArrowBackIosIcon /> {offeringDetail ? offeringDetail.name : ''}
						</div>
					</div>
					
				}
				</div>
			</div>
			<div className="col-md-4">
				<div className="offering-bounceuser-title">Email Bounces</div>
			</div>
		</div>

		return (
			<Dashboard title="ClickIPO Offerings" pageTitle="Bounce List" >
				<div className="offering_userbounce">
					<div>
						{
							<div className="col-md-12">
								<div style={{ marginTop: 16 }}>{headers}</div>
								<div style={{ marginTop: 30 }}>
									<div className="user-bounce-list-table">
										<GridTable
											columns={this.state.cols}
											rows={userBouncesListData}
											isPaginated={true}
											isVirtualScroll={true}
											showSearch={true}
											showRowsInformation={false}
											isHeaderSticky={true}
										/>
									</div>
									{
										(showProspectusView) &&
										<div className="row" style={{ marginTop: 20 }} ref='prospect'>
											<div className="col-md-6">
												<div className="bounces_email_body">
													<div dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(emailBody)} } ></div>
												</div>
											</div>
											<div className="col-md-6">
												<div className="bounces_prospectus_body">
													<div className="prospectus_body_content">
														<div dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(userProspectus)} } id="printdiv" ></div>
													</div>
													<div className="row">
														<div className="col-md-12">
															<div className="text_center">
																<button className="btn prospect_resend_btn" onClick={() => this.handleResendProspect()}>Resend</button>
																<button className="btn prospect_resend_btn" onClick={() => this.openPrientModal()} >Print</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									}
									<Dialog
										onClose={this.closePrientModal}
										aria-labelledby="customized-dialog-title"
										open={this.state.showPrintmodal}
										maxWidth={"sm"}
										fullWidth={true}
										scroll={'paper'}
										>
										<DialogTitle onClose={this.closePrientModal} className="offering-detail-title">Confirmation</DialogTitle>
										<DialogContent>
											<div className="col-md-12">
												<div>
													<span className="bouncePrintModelheader">
														I {this.state.profileData && this.state.profileData.first_name ? this.state.profileData.first_name : ''} {this.state.profileData && this.state.profileData.last_name ? this.state.profileData.last_name : ''}, take responsibility of printing and mailing the prospectus to the user. ClickIPO can mark this message as delivered.<br/> If you select 'Cancel' then the option to print the prospectus from here will not be available. In that case you will have to go to the 'Offerings' tab, click on the offering name and then locate the prospectus to print and mail. 
													</span>
												</div>
											</div>
										</DialogContent>
										<DialogActions style={{display: 'block'}}>
											<div style={{textAlign: 'center'}}>
												<Button variant="contained" color="primary" onClick={() => this.handleResendPrint()} style={{marginLeft: 10, marginRight: 10}}>Confirm</Button>
												<Button variant="contained" color="secondary" onClick={() => this.closePrientModal()} style={{marginLeft: 10, marginRight: 10}}>Cancel</Button>
											</div>
										</DialogActions>
									</Dialog>
									{/* {
										(this.state.showPrintmodal) &&
									
										<div className="backdrop" style={{height:"100%", position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', padding: 10, zIndex:1}}>
											<div style={{height:"100%"}}  onClick={this.closePrientModal}></div>
											<div className="modal" style={{height: '50%' ,backgroundColor: '#fff',
											borderRadius: 5, width: '30%', position:'absolute', margin: 'auto', paddingTop: 15, display: 'block',top: '15px',overflowY:'scroll',zIndex:9999}} >
												<div className="modalheader">
													<p style={{float:'left',color:"rgb(103, 136, 153)",fontSize:18}}> </p>
													<button className="closeBtn" onClick={this.closePrientModal}>
														<span aria-hidden="true" >&times;</span>
													</button>
												</div>
												<div style={{ margin: '40px 20px 20px 20px' }}>
													<div>
														<span className="bouncePrintModelheader">
															I {this.state.profileData.first_name} {this.state.profileData.last_name}, take responsibility of printing and mailing the prospectus to the user. ClickIPO can mark this message as delivered.<br/> If you select 'Cancel' then the option to print the prospectus from here will not be available. In that case you will have to go to the 'Offerings' tab, click on the offering name and then locate the prospectus to print and mail. 
														</span>
													</div>
												</div>
												<div className="row confirm_cancel_btn">
													<div className="col-md-6">
														<div className="text_center">
															<button className="btn prospect_print_btn" disabled={this.state.isPrintDisable} onClick={ () => {this.handleResendPrint()}}>Confirm</button>
														</div>
													</div>
													<div className="col-md-6">
														<div className="text_center">
															<button className="btn prospect_cancel_btn" onClick={this.closePrientModal}>Cancel</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									} */}
								</div>
							</div>
						}
					</div>
				</div>
			</Dashboard>
		)
	}
}

UserBounceListComponent.propTypes = {
	userBouncesRes: PropTypes.any,
	userBounceDetailRes: PropTypes.any,
	resendUserBouncesRes: PropTypes.any,
	printUserBouncesRes: PropTypes.any,
	profileRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	userBouncesRes: doUserBouncesRes,
	userBounceDetailRes: doUserBounceDetailRes,
	resendUserBouncesRes: doResendUserBouncesRes,
	printUserBouncesRes: doPrintUserBouncesRes,
	profileRes: doProfileRes,
});

function mapDispatchToProps(dispatch) {
	return {
		getUserBounces: (ext_id) => dispatch(getUserBounces(ext_id)),
		doUserBouncesRes: (data) => dispatch(doUserBouncesRes(data)),
		getUserBouncesDetails: (ext_id) => dispatch(getUserBounceDetail(ext_id)),
		doUserBounceDetailRes: (data) => dispatch(doUserBounceDetailRes(data)),
		resendUserBounces: (data) => dispatch(resendUserBounces(data)),
		printUserBounces: (data) => dispatch(printUserBounces(data)),
		getProfile: () => dispatch(getProfile()),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(UserBounceListComponent);
