import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './investors.scss';
import Dashboard from '../../component/Dashboard';
import { getInvestors, doInvestorsRes } from '../../actions/investorsActions';
import { numberWithCommasAndDollar} from '../../helper/helper';
import Button from '@material-ui/core/Button';
import GridTable from '@nadavshaar/react-grid-table';
let isGetInvestors = false;
class InvestorsComponent extends React.PureComponent {

	constructor() {
		super();
		isGetInvestors = false;
		this.inputElement = null;
		const columns = [
			{ id: 1, field: 'user_name', label: 'Name', visible: true, sortable: true, cellRenderer: this.fullNametemplate },
			{ id: 2, field: 'email', label: 'Email', visible: true, sortable: true },
			{ id: 3, field: 'account_id', label: 'Account #', visible: true, sortable: true },
			{ id: 4, width: '250px', field: 'offering_participated_cnt', label: '# of IPOs Participated', visible: true, sortable: true },
			{ id: 5, width: '220px', field: 'allocated_total_cnt', label: '# of IPOs Allocated', visible: true, sortable: true },
			{ id: 6, width: '210px', field: 'active_cob_cnt', label: '# of Active COBs', visible: true, sortable: true },
			{ id: 7, width: '250px', field: 'cob_notional', label: 'Total notional of COBs', visible: true, sortable: true, cellRenderer: this.totalCobNotionalTemplate },
			{ id: 8, width: '250px', field: 'allocation_notional', label: 'Total notional allocated', visible: true, sortable: true, cellRenderer: this.nationalAllocatedtemplate }
		];
		this.state = {
			investorsList: [],
			cols: columns,
		  };
	}

	componentDidMount() {
		isGetInvestors = true;
		this.props.getInvestors();
	}

	static getDerivedStateFromProps(props, state) {
		if (props.investorsRes) {
			if (props.investorsRes.data && props.investorsRes.data.investorsList) {
				if (props.investorsRes.data.investorsList.message === 'Success' && isGetInvestors) {
					isGetInvestors = false;
					let investorData = props.investorsRes.data.investorsList.data;
                    for (let i = 0; i < investorData.length; i++) {

                        Object.keys(investorData[i]).forEach((field) => { /*eslint-env es6*/
                        if (investorData[i][field] === null) {
                            investorData[i][field] = "";
                        }
                        })
                    };
					if (investorData && investorData.length > 0) {
						for (let item of investorData) {
							item.account_id = item.account_status.account_id;
							item.offering_participated_cnt = item.offering_data.offering_participated_cnt === null ? 0 : item.offering_data.offering_participated_cnt;
							item.allocated_total_cnt = item.offering_data.allocated_total_cnt === null ? 0 : item.offering_data.allocated_total_cnt;
							item.active_cob_cnt = item.offering_data.active_cob_cnt === null ? 0 : item.offering_data.active_cob_cnt;
							item.cob_notional = item.offering_data.cob_notional === null ? 0: item.offering_data.cob_notional;
							item.allocation_notional = item.offering_data.allocation_notional === null ? 0 : item.offering_data.allocation_notional;
						}
					}
					return {
						investorsList: investorData,
					};
				}
			}
		}
		return null;
	}
	goDetail(inverstorInfo){
		 this.props.history.push({ pathname: `/investor-detail`, state: { multiData: this.state.multiSelect, email: inverstorInfo.email } });
	 }
	fullNametemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner green-color-with-pointer"  onClick={()=>this.goDetail(data)}>{data.user_name} </div>
	}
  
	totalCobNotionalTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner"> {numberWithCommasAndDollar(data.offering_data.cob_notional)} </div>
	}
	nationalAllocatedtemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner"> {numberWithCommasAndDollar(data.offering_data.allocation_notional)} </div>
	}
  
	export = () => {
		let csvContent = 'data:text/csv;charset=utf-8,';
			csvContent += 'Name, Email, Account Id, No. of IPOs Participated, No. of IPOs Allocated, No.  of Active COBs, Total notional of COBs, Total notional allocated \r\n';
			this.state.investorsList.forEach(function (rowArray) {
				const newRow = rowArray.user_name + ',' + rowArray.email + ',' + rowArray.account_status.account_id + ',' + rowArray.offering_data.offering_participated_cnt + ',' + rowArray.offering_data.allocated_total_cnt + ',' + rowArray.offering_data.active_cob_cnt + ',' + rowArray.offering_data.cob_notional + ',' + rowArray.offering_data.allocation_notional;
				csvContent += newRow + '\r\n'; // add carriage return
			});
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'investorsList.csv');
		document.body.appendChild(link); // Required for FF
		link.click();
	}
	render() {
		const { investorsList } = this.state;

		let headers =   <div className="row">
					<div className="col-md-6">
						<p className="total-investor-title" style={{textAlign:'left'}}>
                       		Total Number Of Investors : {investorsList.length}
                      	</p>

					</div>

					<div  className="col-md-6" style={{textAlign:'right'}}>
						<Button variant="contained" color="primary" className="btn" onClick={this.export}>Download CSV</Button>
					</div>
				</div>

		return (
			<Dashboard title="ClickIPO Investors" pageTitle="Investors" >
				<div className="investor_content">
					{headers}
					<div className="investors-list-table mt-3">
						<GridTable
							columns={this.state.cols}
							rows={investorsList}
							isPaginated={true}
							isVirtualScroll={true}
							showSearch={true}
							showRowsInformation={false}
							isHeaderSticky={true}
						/>
					</div>
				</div>
			</Dashboard>
		)
	}
}

InvestorsComponent.propTypes = {
	investorsRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	investorsRes: doInvestorsRes,
});

function mapDispatchToProps(dispatch) {
	return {
		getInvestors: () => dispatch(getInvestors()),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(InvestorsComponent);
