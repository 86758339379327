import * as types from './actionTypes';  
import offeringDetailApi from '../api/offeringDetailApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function doOfferingDetail(ext_id) {

  return {
    type: types.DO_OFFERING_DETAILS, 
    ext_id
  };
}

export function doOfferingDetailRes(data) {  
  return {
    type: types.DO_OFFERING_DETAILS_RES, 
    data
  };
}

export function getOfferingDetail(ext_id) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      offeringDetailApi.doOfferingDetail(ext_id).then(data => {
        dispatch(doOfferingDetailRes(data));
        dispatch(doOfferingDetailRes(null));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}

