import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './investorDetail.scss';
import Dashboard from '../../component/Dashboard';
import { getUserDetail, doUserDetailRes } from '../../actions/UserDetailActions';
import { getActiveOffering, doActiveOfferingRes } from '../../actions/activeOfferingActions';
import  MultiSelectReact  from 'multi-select-react';
import Button from '@material-ui/core/Button';
import { numberWithCommasAndDollar, convertDateFormat, numberWithCommas  } from '../../helper/helper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MailIcon from '@material-ui/icons/Mail';
import GridTable from '@nadavshaar/react-grid-table';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
let isOfferingOrder = false;
let isOffDetail = false;
class InvestorDetailComponent extends React.PureComponent {

	constructor(props){
		super(props);
		isOfferingOrder = false;
		isOffDetail = false;
		const columns = [
			{ id: 1, width: '200px', field: 'company_name', label: 'Name', visible: true, sortable: true, cellRenderer: this.companyNametemplate },
			{ id: 2, field: 'date', label: 'Anticipated Date', visible: true, sortable: true, cellRenderer: this.titleTemp },
			{ id: 3, field: 'buying_power', label: 'IPO Buying Power', visible: true, sortable: true, cellRenderer: this.buyingPowertemplate },
			{ id: 4, width: '220px', field: 'requested_amount', label: 'Req Amount', visible: true, sortable: true, cellRenderer: this.amountTemplate },
			{ id: 5, width: '210px', field: 'requested_shares', label: 'Req Share', visible: true, sortable: true, cellRenderer: this.sharesTemplate },
			{ id: 6, width: '210px', field: 'allocated_amount', label: 'Allocated Amount', visible: true, sortable: true, cellRenderer: this.amountTemplate },
			{ id: 7, width: '210px', field: 'allocated_shares', label: 'Allocated Share', visible: true, sortable: true, cellRenderer: this.sharesTemplate },
			{ id: 8, width: '210px', field: 'order_status', label: 'Status', visible: true, sortable: true, cellRenderer: this.offeringStatusTemplate },
			{ id: 9, width: '210px', field: 'communication', label: 'Comms', visible: true, sortable: true, cellRenderer: this.communicationTemplate }
		];
		this.state = {
			userDetail: '',
			orderList: [],
			userEmail: '',
			cols: columns,
			multiSelect: [
				{ label: 'Active Orders', value: true, id: 1 },
				{ label: 'Closed Orders', value: false, id: 2 },
				{ label: 'Cancelled Orders', value: false, id: 3 }
			],
			globalFilter: '',
			orderInfo: null
		}
	}
	componentDidMount() {
		if (this.props.location.state) {
			if (this.props.location.state.multiData) {
				for (let multi of this.props.location.state.multiData) {
					if (multi.label === "Effective" && multi.value === true) {
						this.setState({
							multiSelect:
								[
									{ label: 'Active', value: this.props.location.state.multiData[0].value, id: 1 },
									{ label: 'Closed', value:  this.props.location.state.multiData[1].value, id: 2 },
									{ label: 'Cancelled', value: this.props.location.state.multiData[2].value, id: 3 },
								],
						});
					} else {
						this.setState({
							multiSelect: [
								{ label: 'Active', value: this.props.location.state.multiData[0].value, id: 1 },
								{ label: 'Closed', value:  this.props.location.state.multiData[1].value, id: 2 },
								{ label: 'Cancelled', value: this.props.location.state.multiData[2].value, id: 3 },
							],
						});
					}
				}

			}
		}

		if (this.props.location.state && this.props.location.state.email) {
			this.setState({
				userEmail: this.props.location.state.email,
			});
			this.props.getInvestorDetail(this.props.location.state.email);
			isOffDetail = true;

			this.props.getActiveOffering(this.props.location.state.email);
			isOfferingOrder = true;
		} else {
			this.setState({
				userEmail: '',
			});
		}
		
	}
	
	static getDerivedStateFromProps(props, state) {
		if (props.userDetailRes) {
			if (props.userDetailRes.data && props.userDetailRes.data.userDetail) {
				if (props.userDetailRes.data.userDetail.error === 0 && isOffDetail) {
					isOffDetail = false;
					return {
						userDetail: props.userDetailRes.data.userDetail.data
					};
				}
			}
		}
		if (props.activeOfferingRes) {
			if (props.activeOfferingRes.data && props.activeOfferingRes.data.activeOffering) {
				if (props.activeOfferingRes.data.activeOffering.error === 0 && isOfferingOrder) {
					isOfferingOrder = false;
					let arrData = props.activeOfferingRes.data.activeOffering.data;
                    for (let i = 0; i < arrData.length; i++) {

                        Object.keys(arrData[i]).forEach((field) => { /*eslint-env es6*/
                        if (arrData[i][field] === null) {
                            arrData[i][field] = "";
                        }
                        })
                    };
					return {
						orderList: arrData
					};
				}
			}
		}
		return null;
	}

	goBackNavigate = () => {
		this.props.history.goBack();
	}

	export = () => {
		let csvContent = 'data:text/csv;charset=utf-8,';
			csvContent += 'Name, Anticipated Date, IPO Buying Power, Req Amount, Req Share, Req Amount, Allocated Amount, Allocated Share, Status \r\n';
			this.state.orderList.forEach(function (rowArray) {
				const newRow = rowArray.company_name + ',' + rowArray.date + ',' + rowArray.buying_power + ',' + rowArray.requested_amount + ',' + rowArray.requested_shares + ',' + rowArray.allocated_amount + ',' + rowArray.allocated_shares + ',' + rowArray.order_status;
				csvContent += newRow + '\r\n'; // add carriage return
			});
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'orderList.csv');
		document.body.appendChild(link); // Required for FF
		link.click();
	}
	titleTemp = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (value) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex'}}>
					<Tooltip title={value !== undefined ? value : ''} interactive >
						<div className="less-text" style={{width: '90%'}}>{value}</div>
					</Tooltip>
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button color="primary" style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
					</Tooltip>
				</div>
			)
		}
	}
	amountTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (value) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex'}}>
					<Tooltip title={value !== undefined ? numberWithCommasAndDollar(value) : ''} interactive >
						<div className="less-text" style={{width: '90%'}}>{numberWithCommasAndDollar(value)}</div>
					</Tooltip>
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button color="primary" style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
					</Tooltip>
				</div>
			)
		}
	}
	sharesTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (value) {
			return (
				<div className='rgt-cell-inner' style={{overflow: 'hidden', display: 'flex'}}>
					<Tooltip title={value !== undefined ? numberWithCommas(value) : ''} interactive >
						<div className="less-text" style={{width: '90%'}}>{numberWithCommas(value)}</div>
					</Tooltip>
					<Tooltip title={'Copy to clipboard'} interactive >
						<Button color="primary" style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
					</Tooltip>
				</div>
			)
		} else {
		  return <div className="rgt-cell-inner"> N/A </div>;
		}
		
	}
	requestedSharesTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (data.requested_shares) {
		  return <div className="rgt-cell-inner"> {numberWithCommas(data.requested_shares)} </div>;
		} else {
		  return <div className="rgt-cell-inner"> N/A </div>;
		}
		
	}
	
	communicationTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		return <div className="rgt-cell-inner"> <Button variant="contained" color="primary" onClick={() => { this.openModel(data); }}><MailIcon /></Button> </div>;
	}

	openModel = (user) => {
		this.props.history.push({
			pathname: '/investor-communication',
			state: { userInfo: user, emailId: this.state.userEmail }
		})
	}
	buyingPowertemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (data.available_balance) {
			return <div className="rgt-cell-inner">{numberWithCommasAndDollar(parseFloat(data.available_balance))} </div>;
		} else { 
			return <div className="rgt-cell-inner">{numberWithCommasAndDollar(parseFloat(0))} </div>;
		}	
	}
	
	optionClicked = (optionsList) => {
		this.setState({ multiSelect: optionsList });
	}

	selectedBadgeClicked = (optionsList) => {
		this.setState({ multiSelect: optionsList });
	}
	
	goDetail = (inverstorInfo) => {
		this.props.history.push({ pathname: `/offering-investors/${inverstorInfo.offering_info.ext_id}`, state: { multiData: this.state.multiSelect, email: this.state.userEmail, offeringStatus: inverstorInfo.order_status, offeringData: inverstorInfo.offering_info } });
	}
	companyNametemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		if (data.active === true) {
		  return <div className="rgt-cell-inner  green-color-with-pointer" onClick={()=> this.goDetail(data)}>{data.company_name} </div>;
		} else {
		  return <div className="rgt-cell-inner  closeofferingColor green-color-with-pointer" onClick={()=> this.goDetail(data)}>{data.company_name}</div>;
		}
	}
	offeringStatusTemplate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
		let classNames = 'rgt-cell-inner rgt-text-truncate ';
		if (data.status !== 'Active') {
		  classNames = 'rgt-cell-inner rgt-text-truncate red_color';
		}
		return <div className={classNames} style={{overflow: 'hidden', display: 'flex'}}>
			<Tooltip title={value !== undefined ? numberWithCommas(value) : ''} interactive >
				<div className="less-text" style={{width: '90%'}}>{numberWithCommas(value)}</div>
			</Tooltip>
			<Tooltip title={'Copy to clipboard'} interactive >
				<Button color="primary" style={{padding: 4, minWidth: 10, color: "#7e817c"}} onClick={() =>  navigator.clipboard.writeText(value)} > <FileCopyIcon fontSize="small"/> </Button>
			</Tooltip>
		</div>;
	}
	render() {
		const { orderList, userDetail } = this.state;
		let orderFilter = [];
		const selectedOptionsStyles = {
			color: '#3c763d',
			backgroundColor: '#dff0d8',
		};

		const optionsListStyles = {
			backgroundColor: '#8DC73F',
			color: '#fff',
		};

		let headers =   <div className="row">
			<div className="col-md-4">
				<div className="offeringDetailSubheader">
				{(userDetail && userDetail.first_name) && 
					<div className="col-sm-12 offeringinvestors-top_bar">
					
						<div style={{textAlign: 'left'}}>
							<div className="callback-back" onClick={() => { this.goBackNavigate(); }}>
								<ArrowBackIosIcon /> {userDetail ? userDetail.first_name : ''}  {userDetail ? userDetail.last_name  : ''}
							</div>
							<span className="sub">Connected : 
							{userDetail ? userDetail.connected_date : ''}
							</span>
						</div>
						<div></div>
					</div>
					
				}
				</div>
			</div>
			<div className="col-md-2">
			<MultiSelectReact
				options={this.state.multiSelect}
				optionClicked={this.optionClicked.bind(this)}
				selectedBadgeClicked={this.selectedBadgeClicked.bind(this)}
				selectedOptionsStyles={selectedOptionsStyles}
				optionsListStyles={optionsListStyles}
			/>
			</div>
			<div className="col-md-2">
				<Button variant="contained" color="primary" fullWidth={true} onClick={this.export}>Download CSV</Button>
			</div>
        </div>
		
		if (orderList) {
			for (let items of orderList) {
				items.communication = '';
				if (this.state.multiSelect[0].value) {
					if (items.order_status === 'active') {
						items.active = true;
						items.status = 'Active';
						items.date = convertDateFormat(items.anticipated);
						items.allocated_shares = 0;
						orderFilter.push(items);
					}
				}
				if (this.state.multiSelect[1].value) {
					if (items.order_status === 'closed') {
						items.active = false;
						items.status = 'Closed';
						items.date = convertDateFormat(items.anticipated);
						orderFilter.push(items);
					}
				}
				if (this.state.multiSelect[2].value) {
					if (items.order_status === 'cancelled') {
						items.active = false;
						items.status = 'Cancelled';
						items.date = convertDateFormat(items.anticipated);
						orderFilter.push(items);
					}
				}
			}
		}
		orderFilter = this.state.searching ? orderFilter.filter((row) => row.fullname.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1) : orderFilter;
		return (
			<Dashboard title="Investor Details" pageTitle="Investor Details" >
				<div className="offering_investors_content">
					<div>
						{
							<div className="col-md-12">
								<div>{headers}</div>
								<div className="investors-detail-table" style={{ marginTop: 30 }}>
									<GridTable
										columns={this.state.cols}
										rows={orderFilter}
										isPaginated={true}
										isVirtualScroll={true}
										showSearch={true}
										showRowsInformation={false}
										isHeaderSticky={true}
									/>
								</div>
							</div>
							
						}
					</div>
				</div>
			</Dashboard>
		)
	}
}

InvestorDetailComponent.propTypes = {
	userDetailRes: PropTypes.any,
	activeOfferingRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	userDetailRes: doUserDetailRes,
	activeOfferingRes: doActiveOfferingRes,
});

function mapDispatchToProps(dispatch) {
	return {
		getInvestorDetail: (email) => dispatch(getUserDetail(email)),
		getActiveOffering: (email) => dispatch(getActiveOffering(email)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(InvestorDetailComponent);
